import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { useLocale } from "lib/hooks/useLocale";
import { getDefaultShippingHandle } from "lib/utils/checkout";
import { analyticsEvents } from "lib/utils/constants";
import { capitalizeFirstCharacter, scrollToID, truncateString } from "lib/utils/helpers";
import React, { useEffect, useMemo } from "react";
import { ArrowRight, MapPin } from "react-feather";

interface AddressSectionProps {}

const AddressSection: React.FC<AddressSectionProps> = () => {
  const { t } = useLocale();
  const {
    state: { user },
  } = useUserContext();
  const {
    state: { isC2P, shippingHandles, activeComponent },
    actions: { setCheckoutModal },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const billingAddressEnabled = merchant?.addressConfig?.isBillingAddressEnabled;

  const selectedShippingHandleEtd = useMemo(() => {
    return getDefaultShippingHandle(shippingHandles)?.etd ?? "";
  }, [shippingHandles]);

  useEffect(() => {
    if (activeComponent === "ADDRESS_SECTION") {
      scrollToID("address-section");
    }
  }, [activeComponent]);

  return (
    <div
      id="address-section"
      className={`flex flex-col px-3 pt-1 ${billingAddressEnabled ? "pb-1.5" : "pb-3"}`}>
      <div className={`border-base flex w-full flex-col items-center gap-3 bg-white p-3`}>
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex items-center justify-between space-x-2">
            <div className="flex items-center space-x-2">
              <MapPin className="h-4 w-4 text-coal-dark outline-none" strokeWidth={2.5} />
              <h2 className="text-sm font-medium text-coal-dark">{t("deliver_to")}</h2>
              {Boolean(user?.default_shipping_address?.type) && (
                <div className="flex w-fit rounded-xl bg-gray-lighter px-2 py-0.5">
                  <p className="w-full px-[3px] text-left text-sm font-medium text-coal-light">
                    {truncateString(
                      capitalizeFirstCharacter(
                        user?.default_shipping_address?.type ? user?.default_shipping_address?.type : "",
                      ),
                      12,
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
          {!isC2P && (
            <span
              className="inline-flex cursor-pointer space-x-0.5 text-coal-dark"
              onClick={() => {
                sendAnalyticsEvent({
                  eventName: analyticsEvents.FLO_DELIVERY_ADDRESS_CHANGE_CLICKED,
                  eventType: "click",
                });
                if (Boolean(user?.addresses?.length)) {
                  setCheckoutModal("ADDRESS_LIST");
                  return;
                }
                setCheckoutModal("ADDRESS_NEW");
              }}>
              <span className="text-sm font-medium">
                {Boolean(user?.addresses?.length) ? t("change") : t("add_address")}
              </span>
              <ArrowRight strokeWidth={2.5} className="h-4 w-4" />
            </span>
          )}
        </div>

        {Boolean(user?.addresses?.length) && (
          <div className="flex w-full flex-col space-y-1">
            <p className="w-full text-left text-sm font-normal text-gray-dark">
              {user?.default_shipping_address?.address}
            </p>
          </div>
        )}
        {!Boolean(shippingHandles.length) && Boolean(user?.addresses?.length) && (
          <div className="flex w-full justify-start">
            <span className="text-xs font-medium text-ouch">{t("pincode_not_serviceable")}</span>
          </div>
        )}
        {Boolean(selectedShippingHandleEtd) && (
          <p className="flex w-full grow justify-start overflow-hidden text-ellipsis text-left text-xs font-medium text-coal-light">
            <span className="truncate">
              {t("delivery_information_with_days", { days: selectedShippingHandleEtd })}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default AddressSection;
