import React, { useEffect, useState } from "react";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { getTotalItems, getItemsTotalAndValue } from "lib/utils/checkout";
import { getStartNumberFromString } from "lib/utils/helpers";
import { Transition } from "@headlessui/react";

interface CheckoutItemsCountProps {
  showOrderSummaryTotalTxt: boolean;
}

const CheckoutItemsCount: React.FC<CheckoutItemsCountProps> = ({ showOrderSummaryTotalTxt }) => {
  const {
    state: { checkoutItems, billing },
  } = useCheckoutContext();
  const [orderSummarySubTxt, setOrderSummarySubTxt] = useState<string>("");
  const [updatedCartItemsCount, setUpdatedCartItemsCount] = useState<number>(0);
  const [isItemChangeVisible, setIsItemChangeVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!Boolean(billing)) return;
    const orderSummaryStr = getItemsTotalAndValue(
      checkoutItems,
      billing.total_payable,
      showOrderSummaryTotalTxt,
    );
    const cartItemsDifference = getTotalItems(checkoutItems) - getStartNumberFromString(orderSummarySubTxt);
    if (typeof orderSummarySubTxt === "string" && Boolean(cartItemsDifference)) {
      setUpdatedCartItemsCount(cartItemsDifference);
      setIsItemChangeVisible(true);
    }
    setTimeout(() => setIsItemChangeVisible(false), 1700);
    setTimeout(() => setUpdatedCartItemsCount(0), 2000);
    setOrderSummarySubTxt(orderSummaryStr);
  }, [billing, checkoutItems]);

  return (
    <div className="relative">
      <Transition
        show={isItemChangeVisible}
        enter="transition ease-out duration-700 transform"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-300 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-2"
        className={`absolute w-max`}>
        <span
          className={`rounded-[4px] ${
            updatedCartItemsCount > 0 ? "bg-yay-lighter px-1 text-yay-dark" : "bg-white"
          }`}>
          {`${updatedCartItemsCount > 0 ? "+" : ""}${updatedCartItemsCount} ${
            updatedCartItemsCount > 1 || updatedCartItemsCount < -1 ? "items" : "item"
          }`}
        </span>
      </Transition>

      <span className={`${isItemChangeVisible ? "opacity-0" : "opacity-100"}`}>{orderSummarySubTxt}</span>
    </div>
  );
};

export default CheckoutItemsCount;
