import { publishPostMessage } from "lib/utils/helpers";
import { UserLoginType, UserType } from "lib/types/user";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { Value as PhoneInputValueType } from "react-phone-number-input";
import { eventTypes } from "lib/utils/constants";

const Context = createContext<UserContext>({ state: {}, actions: {} } as UserContext);

export enum ActionType {
  SET_USER,
  SET_USER_LOGIN_TYPE,
  SET_MARKETING_CONSENT,
}

export type Action =
  | { type: ActionType.SET_USER; payload: UserType }
  | { type: ActionType.SET_USER_LOGIN_TYPE; payload: UserLoginType }
  | { type: ActionType.SET_MARKETING_CONSENT; payload: boolean };

export interface UserState {
  user: UserType;
  userLoginType: UserLoginType;
  marketingConsent?: boolean;
}

export interface UserContext {
  state: UserState;
  actions: {
    setUserData: (data: any) => void;
    setUserLoginType: (type: UserLoginType) => void;
    setMarketingConsent: (value: boolean) => void;
  };
}

function reducer(state: UserState, action: Action): UserState {
  switch (action.type) {
    case ActionType.SET_USER: {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    }
    case ActionType.SET_USER_LOGIN_TYPE: {
      return {
        ...state,
        userLoginType: action.payload,
      };
    }
    case ActionType.SET_MARKETING_CONSENT: {
      return {
        ...state,
        marketingConsent: action.payload,
      };
    }
  }
  return state;
}

const UserInitialState: UserState = {
  user: {
    uid: "",
    email: "",
    phone: "" as PhoneInputValueType,
    name: "",
    default_billing_address: undefined,
    default_shipping_address: undefined,
    addresses: undefined,
    hasHomeAddr: false,
    hasWorkAddr: false,
  },
  userLoginType: "na",
};

export const UserProvider: React.FC<React.PropsWithChildren<{ initialState?: UserState }>> = ({
  initialState = UserInitialState,
  children,
}) => {
  const [reducerState, dispatch] = useReducer(reducer, initialState);

  const setUserData = useCallback((data: any) => {
    dispatch({
      type: ActionType.SET_USER,
      payload: data,
    });
  }, []);

  const setUserLoginType = useCallback((type: UserLoginType) => {
    dispatch({
      type: ActionType.SET_USER_LOGIN_TYPE,
      payload: type,
    });
  }, []);

  const setMarketingConsent = useCallback((value: boolean) => {
    dispatch({
      type: ActionType.SET_MARKETING_CONSENT,
      payload: value,
    });
  }, []);

  const actions = useMemo(
    () => ({
      setUserData,
      setUserLoginType,
      setMarketingConsent,
    }),
    [setUserData, setUserLoginType, setMarketingConsent],
  );

  useEffect(() => {
    if (reducerState.user.uid) {
      publishPostMessage(eventTypes.FLO_GHOST_USER, { user: reducerState.user.uid });
    }
  }, [reducerState.user]);

  return (
    <Context.Provider
      value={{
        state: reducerState,
        actions,
      }}>
      {children}
    </Context.Provider>
  );
};

export function useUserContext() {
  if (!Boolean(Context)) throw new Error("useUserContext must be used within a UserProvider");
  const UserContext = useContext(Context);
  return UserContext as UserContext;
}
