import expiredIcon from "assests/images/expired.png";
import { useLocale } from "lib/hooks/useLocale";

const CheckoutExpired = () => {
  const { t } = useLocale();
  return (
    <div className="flex h-full w-full flex-col justify-center">
      <div className="flex flex-col items-center space-y-3 rounded-xl border border-[#EFD080] bg-[#FDFBF6] py-4 px-2">
        <img src={expiredIcon} alt="expire" className="h-8 w-8" />
        <div className="w-full space-y-2 text-center">
          <h2 className="text-medium font-semibold text-carbon-dark">{t("link_expired")}</h2>
          <p className="text-sm text-coal-light">{t("link_expired_description")}</p>
        </div>
      </div>
    </div>
  );
};

export default CheckoutExpired;
