import React, { useCallback, useEffect, useState } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { useAuthContext } from "lib/contexts/AuthProvider";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { addDefaultCountryCode, classNames, inIframe, setPhoneNumberSpacing } from "lib/utils/helpers";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, analyticsTypes, constants } from "lib/utils/constants";
import { errorToast } from "lib/utils/toasters";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  Value as PhoneInputValueType,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Edit } from "react-feather";
import { useUserContext } from "lib/contexts/UserProvider";
import useForm, { FormField } from "lib/hooks/useForm";
import { OTPChannel, OTPVerificationDetails, UserLoginType } from "lib/types/user";
import { sendOtpWithPhoneNumber, verifyOtp, resentOtp, OTPDeliveryType } from "lib/utils/auth";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import MarketingConsent from "components/common/MarketingConsent";
import OTPForm from "components/auth/forms/OTPForm";
import { CtaConfig } from "lib/types/merchant";
import { getRequest } from "lib/core/apiClient";
import useInterval from "lib/hooks/useInterval";
import { invokeTruecallerDeeplink } from "lib/third-party/truecaller";
import { useSearchParams } from "react-router-dom";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import useReCaptcha from "lib/hooks/useReCaptcha";

interface LoginProps {
  context: "AUTH" | "SSO";
  onSuccess?: (...args: any) => void;
}

let pollCounter = 1;
let isPhoneInputFirstFocus = false;

const Login: React.FC<LoginProps> = ({ context, onSuccess }) => {
  const { t } = useLocale();
  const {
    state: { thirdPartyAuth },
    actions: { login },
  } = useAuthContext();
  const {
    state: { user },
    actions: { setUserLoginType },
  } = useUserContext();
  const {
    state: { checkoutView, originUrl, shopifySessionId },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { generateReCaptchaToken } = useReCaptcha();

  const [searchParams] = useSearchParams();
  const isCheckoutFromCart = Boolean(searchParams.get("fromCart"));

  const [verifyOTPDialag, setVerifyOTPDialag] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(-1);
  const [counterExpired, setCounterExpired] = useState<boolean>(false);
  const [phoneInputFocused, setPhoneInputFocused] = useState<boolean>(false);
  const [resendOTPCounter, setResendOTPCounter] = useState<number>(0);
  const [wrongOTPCounter, setWrongOTPCounter] = useState<number>(0);
  const [contextId, setContextId] = useState<string>("");
  const [isLoginStatusPolling, setIsLoginStatusPolling] = useState<boolean>(false);
  const [authRequestId, setAuthRequestId] = useState<string>("");

  const isSso = context === "SSO";

  const {
    handleSubmit,
    inputProps,
    setValueOf,
    setErrors,
    handleFieldChange,
    state: { values, errors },
  } = useForm({
    initialState: verifyOTPDialag
      ? {
          otp: "",
        }
      : { phoneNumber: "" as PhoneInputValueType },
    validationSchema: verifyOTPDialag
      ? {
          otp: {
            required: t("enter_your_otp"),
            numeric: t("otp_incorrect"),
            length: {
              limit: 4,
              message: t("otp_incorrect"),
            },
            formatters: ["NUMERIC"],
          },
        }
      : {
          phoneNumber: {
            required: t("invalid_phone_number"),
            phone: t("invalid_phone_number"),
            formatters: ["PHONE"],
          },
        },
  });

  useEffect(() => {
    if (counter === 0) setCounterExpired(true);
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (!Boolean(user?.phone)) return;
    setValueOf("phoneNumber", user?.phone);
  }, [user]);

  // useEffect(() => {
  //   if (originUrl?.length >= 24) return;
  //   if (inIframe()) {
  //     window.addEventListener("message", (e: MessageEvent) => {
  //       if (e?.data && e?.data?.type === "auto_read_otp") {
  //         setValueOf("otp", e?.data?.payload);
  //       }
  //     });
  //   } else {
  //     autoReadOTP();
  //   }
  //   return () => {
  //     window.removeEventListener("message", () => {});
  //   };
  // }, []);

  //Auto Read OTP for redirect
  const autoReadOTP = () => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();
      const credentialOptions = {
        otp: { transport: ["sms"] },
        signal: ac.signal,
      };
      navigator.credentials
        .get(credentialOptions)
        .then((otp: any) => {
          setValueOf("otp", otp?.code);
          ac.abort();
        })
        .catch((err) => {
          ac.abort();
          console.error(err);
        });
    }
  };

  //Validate OTP handler
  const validateOtp = async (otp: string) => {
    if (Boolean(otp) && otp?.length !== 4) return;
    setErrors({ otp: { status: false } });
    setIsLoading(true);

    try {
      // OTP entered event
      sendAnalyticsEvent({
        eventName: isSso ? analyticsEvents.FLO_SSO_OTP_ENTERED : analyticsEvents.FLO_AUTH_OTP_ENTERED,
        eventType: "load",
      });
      const result = await verifyOtp(contextId, otp);
      const authToken = result?.access_token;
      const authTokenExpiry = result?.access_token_expires_at;
      const refreshToken = result?.refresh_token;
      const refreshTokenExpiry = result?.refresh_token_expires_at;

      setIsLoading(false);
      login(authToken, refreshToken, authTokenExpiry, refreshTokenExpiry, isCheckoutFromCart);
      if (isSso) {
        // if (onSuccess) onSuccess(authToken, refreshToken, authTokenExpiry, refreshTokenExpiry);
        return;
      }

      setUserLoginType(analyticsEvents.FLO_RETURN_USER_LOGIN as UserLoginType);

      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_RETURN_USER_LOGIN,
        eventType: "flo_action",
        metaData: {
          userData: {
            type: analyticsEvents.FLO_RETURN_USER_LOGIN,
          },
          authSource: "SHOPFLO",
        },
      });
    } catch (e) {
      setIsLoading(false);
      setErrors({ otp: { status: true, message: t("otp_incorrect") } });
      setWrongOTPCounter((oldValues) => oldValues + 1);
    }
  };

  // Handles Phone number submission
  const handlePhoneNumberSubmit = async ({ phoneNumber }: { phoneNumber: string }) => {
    if (!(isPossiblePhoneNumber(phoneNumber) && isValidPhoneNumber(phoneNumber))) {
      errorToast(t("invalid_phone"));
      return;
    }
    try {
      setIsLoading(true);
      setErrors({ phoneNumber: { status: false }, otp: { status: false, showAlert: false } });
      setValueOf("otp", "");
      let verificationDetails: OTPVerificationDetails = {
        identifier: addDefaultCountryCode(phoneNumber),
        merchantId: merchant?.merchantId ?? "",
        domain: inIframe() ? originUrl : constants?.CHECKOUT_HOST,
      };

      let ssoRequestId;
      let contextMetada: OTPDeliveryType = {
        context: "AUTH",
      };
      if (isSso) {
        ssoRequestId = searchParams.get("request-id");
        if (ssoRequestId) {
          contextMetada = {
            context: "SSO",
            requestId: ssoRequestId,
          };
        }
      }

      const reCaptchaToken = await generateReCaptchaToken("login");
      const result = await sendOtpWithPhoneNumber(verificationDetails, contextMetada, reCaptchaToken);
      setCounter(30);
      setCounterExpired(false);
      setResendOTPCounter((oldValues) => oldValues + 1);
      setIsLoading(false);
      if (!Boolean(result)) return;
      const isExistingUser: boolean = result.otp_required ?? result.existing_customer;
      setContextId(result.context_id);

      //Phone entered event
      sendAnalyticsEvent({
        eventName: isSso ? analyticsEvents.FLO_SSO_PHONE_ADDED : analyticsEvents.FLO_AUTH_PHONE_ENTERED,
        eventType: isSso ? "load" : "click",
        metaData: {
          userType: isExistingUser ? "repeat" : "guest",
        },
      });

      if (!isExistingUser) {
        const authToken = result.token?.access_token;
        const expiry = result.token?.access_token_expires_at;
        login(authToken, "", expiry, undefined, isCheckoutFromCart);
        setUserLoginType(analyticsEvents.FLO_GUEST_LOGIN as UserLoginType);
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_GUEST_LOGIN,
          eventFor: [analyticsTypes.SF_ANALYTICS],
          eventType: "flo_action",
          metaData: {
            userData: {
              type: analyticsEvents.FLO_GUEST_LOGIN,
            },
            authSource: "SHOPFLO",
          },
        });
        return;
      }
      searchParams.set("phone", phoneNumber);
      setVerifyOTPDialag(true);
      sendAnalyticsEvent({
        eventName: isSso ? analyticsEvents.FLO_SSO_OTP_LOADED : analyticsEvents.FLO_AUTH_OTP_LOADED,
        eventType: "load",
      });
    } catch (err: any) {
      setIsLoading(false);
      if (err?.response?.status === 400) {
        setErrors({
          phoneNumber: {
            status: true,
            message: t("invalid_phone_number"),
          },
        });
      } else {
        errorToast(t("otp_fetch_failed"));
      }
    }
  };

  const handleResendOtp = async (channel: OTPChannel) => {
    setIsLoading(true);
    sendAnalyticsEvent({
      eventName: isSso ? analyticsEvents.FLO_SSO_OTP_RESEND : analyticsEvents.FLO_AUTH_OTP_RESEND,
      eventType: "click",
    });
    setValueOf("otp", "");
    try {
      await resentOtp(contextId, channel);
      setCounter(30);
      setCounterExpired(false);
      setResendOTPCounter((oldValues) => oldValues + 1);
    } catch (err) {
      console.error(err);
    }
    setErrors({ otp: { status: false } });
    setIsLoading(false);
  };

  const getPrimaryButtonText = () => {
    if (isSso) return t("verify_otp");
    const cta = merchant?.primaryCtaConfig?.find(
      (config: CtaConfig) => config.type === checkoutView,
    )?.ctaText;
    return cta;
  };

  const pollThirdPartyAuthStatus = useCallback(async () => {
    if (!authRequestId) {
      stopAuthPolling();
      return;
    }
    let statusResponse;
    try {
      statusResponse = await getRequest(`/auth-callback/${authRequestId}/status`, "AUTH");
      if (statusResponse.status === "VERIFIED") {
        stopAuthPolling();
        const authToken = statusResponse?.metadata?.token_response?.access_token;
        const authTokenExpiry = statusResponse?.metadata?.token_response?.access_token_expires_at;
        const refreshToken = statusResponse?.metadata?.token_response?.refresh_token;
        const refreshTokenExpiry = statusResponse?.metadata?.token_response?.refresh_token_expires_at;
        const isExistingUser = statusResponse?.metadata?.token_response?.existing_user;
        login(authToken, refreshToken, authTokenExpiry, refreshTokenExpiry, isCheckoutFromCart);
        setUserLoginType(
          isExistingUser
            ? (analyticsEvents.FLO_RETURN_USER_LOGIN as UserLoginType)
            : (analyticsEvents.FLO_GUEST_LOGIN as UserLoginType),
        );
        sendAnalyticsEvent({
          eventName: isExistingUser ? analyticsEvents.FLO_RETURN_USER_LOGIN : analyticsEvents.FLO_GUEST_LOGIN,
          eventType: "flo_action",
          metaData: {
            userData: {
              type: isExistingUser ? analyticsEvents.FLO_RETURN_USER_LOGIN : analyticsEvents.FLO_GUEST_LOGIN,
            },
            authSource: "TRUECALLER",
          },
        });

        return;
      }
      if (statusResponse.status === "FAILED" || statusResponse.status === "USER_REJECTED") {
        stopAuthPolling();
        return;
      }
      if (pollCounter >= 30) {
        stopAuthPolling();
        return;
      }
      pollCounter++;
    } catch (e) {
      console.error(e);
      stopAuthPolling();
    } finally {
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_TRUECALLER_ACTION,
        eventType: "flo_action",
        metaData: {
          action: {
            type: statusResponse?.status,
          },
        },
      });
    }
  }, [authRequestId]);

  const onPhoneInputFocus = () => {
    setPhoneInputFocused(true);
    if (Boolean(values?.phoneNumber?.length) || !thirdPartyAuth?.length || isPhoneInputFirstFocus) return;
    const truecallerRequest = thirdPartyAuth[0];
    if (!truecallerRequest) return;
    const authProvider = truecallerRequest.provider;

    switch (authProvider) {
      case "TRUECALLER": {
        setAuthRequestId(truecallerRequest.requestId);
        isPhoneInputFirstFocus = true;
        invokeTruecallerDeeplink(truecallerRequest.redirectUrl);
        setIsLoginStatusPolling(true);
        break;
      }
    }
  };

  const stopAuthPolling = () => {
    pollCounter = 1;
    setIsLoginStatusPolling(false);
  };

  useInterval(pollThirdPartyAuthStatus, isLoginStatusPolling ? 1500 : null);
  return (
    <>
      {isSso && !verifyOTPDialag && (
        <h2 className="text-center text-base font-medium text-coal-dark">
          {t("sso_login_header", { merchant_name: merchant?.displayName })}
        </h2>
      )}
      {Boolean(verifyOTPDialag) ? (
        <>
          {/* OTP Dialog */}
          <div className="flex flex-col gap-5 rounded-xl border border-gray-light p-3">
            {isSso && (
              <div className={`flex w-full flex-row items-center justify-center space-x-2`}>
                <h1 className="space-x-2 text-sm font-normal text-coal-light">
                  <span>{t("verify_otp_header")}</span>
                  <span className="font-medium text-coal-dark">
                    {setPhoneNumberSpacing(values.phoneNumber)}
                  </span>
                </h1>
                <button onClick={() => setVerifyOTPDialag(false)}>
                  <Edit className="h-4 w-4 text-coal-dark" />
                </button>
              </div>
            )}
            <OTPForm
              isLoading={Boolean(isLoading || isLoading)}
              otpLength={4}
              otpValue={values.otp ?? ""}
              handleSubmit={handleSubmit(validateOtp)}
              handleChange={(code: string) => {
                setValueOf("otp", code);
                if (errors?.otp?.status) {
                  setErrors({ otp: { status: false } });
                }
              }}
              validateOtp={validateOtp}
              handleResendOtp={handleResendOtp}
              error={errors?.otp?.status || false}
              errorMessage={t("otp_incorrect_login") || ""}
              autoFocus={true}
              otpCounter={counter}
              otpCounterExpired={counterExpired}
              emailValidation={false}
              showLoginConsent={context !== "SSO"}
              headerComponent={
                context === "AUTH" ? (
                  <div className={`flex w-full flex-row items-center justify-between`}>
                    <h1 className="text-sm font-normal text-coal-dark">
                      {t("verify_otp_header")}
                      <span className="pl-1 font-medium">{setPhoneNumberSpacing(values.phoneNumber)}</span>
                    </h1>
                    <button className="rounded-lg p-2" onClick={() => setVerifyOTPDialag(false)}>
                      <Edit className="h-4 w-4 text-coal-dark" />
                    </button>
                  </div>
                ) : null
              }
              context={isSso ? "sso" : "auth"}
            />
          </div>
        </>
      ) : (
        <>
          <form
            onSubmit={handleSubmit(handlePhoneNumberSubmit)}
            className="flex w-full flex-col items-center">
            <PhoneInput
              defaultCountry="IN"
              {...inputProps(FormField.phoneNumber, {
                onChange: (value: PhoneInputValueType) => {
                  handleFieldChange(FormField.phoneNumber, value);
                },
                onFocus: () => onPhoneInputFocus(),
                onBlur: () => {
                  setPhoneInputFocused(false);
                  // setIsLoginStatusPolling(false);
                },
              })}
              value={values?.phoneNumber ?? user?.phone ?? ("" as PhoneInputValueType)}
              countries={merchant?.addressConfig?.enabledCountries}
              id="flo__auth__phoneInput"
              smartCaret={false}
              className={classNames(
                `placeholder-sm peer h-[3.25rem] w-full appearance-none rounded-xl border border-gray-light px-3 text-base placeholder-transparent`,
                errors.phoneNumber?.status ? "border-ouch" : "",
                Boolean(values?.phoneNumber?.length) && !errors?.phoneNumber?.status
                  ? "border-[#D5E7DB]"
                  : "",
                phoneInputFocused
                  ? isSso
                    ? `border-blakc border px-[12px] shadow-[0px_0px_4px_0px_#00000040] outline-none ring-[1px] ring-black`
                    : "ring-primary-light` border-[1px] !border-primary-dark px-[12px] outline-none ring-[2px]"
                  : "",
              )}
              placeholder={t("phone_number")}
              international={true}
              countryCallingCodeEditable={false}
              autoFocus={true}
              limitMaxLength
              error={errors.phoneNumber?.message}
            />
            <p
              className={`mt-1 w-full px-3 text-center font-normal ${
                errors.phoneNumber?.status ? "self-start text-sm text-ouch" : "text-xs text-coal-light"
              }`}>
              {errors.phoneNumber?.status && errors.phoneNumber?.message}
            </p>
            <div className="mt-3 w-full">
              <PrimaryButton
                type="submit"
                buttonText={getPrimaryButtonText()}
                height="h-14"
                isLoading={isLoading}
                isDisabled={false}
                isCheckout={false}
                showTerms={false}
                className={isSso ? "!bg-black !text-white" : ""}
                id="flo__auth__loginButton"
              />
            </div>

            <div>
              <MarketingConsent />
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Login;
