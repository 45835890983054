import React, { useEffect, useRef } from "react";
import { AutocompleteAttribute } from "lib/types/fields";
import { ErrorType } from "lib/types/address";
import { AlertCircle } from "react-feather";
import CheckCircle from "assests/images/check-circle.svg";

interface InputFieldProps {
  label?: string;
  placeholder?: string;
  id: string;
  name: string;
  autoComplete?: AutocompleteAttribute;
  value?: string;
  required?: boolean;
  maxLength?: number;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onFocus?: (value: any) => void;
  iconComponent?: JSX.Element;
  autoCapitalize?: "on" | "off";
  customClass?: string;
  error?: ErrorType;
  disabled?: boolean;
  tabIndex?: number;
  filled?: boolean;
  autoFocus?: boolean;
  showSuccessIcon?: boolean;
}

const TextArea: React.FC<InputFieldProps> = ({
  label,
  placeholder,
  id,
  name,
  autoComplete,
  value = "",
  required = false,
  maxLength = 200,
  onChange,
  onBlur,
  onFocus,
  iconComponent,
  autoCapitalize = "off",
  customClass,
  error = { status: false, message: "" },
  disabled = false,
  tabIndex = 0,
  filled = false,
  autoFocus = false,
  showSuccessIcon = false,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const MIN_HEIGHT = 56;

  useEffect(() => {
    resizeTextArea();
  }, [value]);

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      if (!Boolean(value)) {
        textAreaRef.current.style.height = `${MIN_HEIGHT}px`;
        return;
      }

      textAreaRef.current.style.minHeight = `${MIN_HEIGHT}px`;
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }
  };

  return (
    <div id={`flo-field-${id}`} className={`w-full ${error.status} flex flex-col`}>
      {Boolean(label) && (
        <label
          htmlFor={id}
          className={`${required ? "required-field" : ""} mb-0.5  text-xxs uppercase text-gray-dark`}>
          {label}
        </label>
      )}
      <div className="relative flex">
        {showSuccessIcon && (
          <div className={`absolute right-3 top-5`}>
            <img src={CheckCircle} className="h-4 w-4" />
          </div>
        )}
        <textarea
          id={id}
          name={name}
          placeholder={placeholder}
          autoFocus={autoFocus}
          className={`transition-inputLabel peer w-full resize-none appearance-none overflow-hidden break-words break-all rounded-xl border px-4 py-4 pr-8 font-normal leading-6 text-charcoal placeholder-transparent focus:border-[1px] focus:border-primary-dark focus:outline-none focus:ring-[2px] focus:ring-primary-light  
          ${error.status ? " border-ouch" : " border-gray-light"} 
          ${disabled ? "border !border-gray-light bg-gray-lighter text-gray-dark" : ""} 
          ${Boolean(value?.length) && !error?.status ? "border-[#D5E7DB]" : ""} ${customClass}`}
          autoComplete={autoComplete}
          aria-label={placeholder}
          maxLength={maxLength}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          autoCapitalize={autoCapitalize}
          disabled={disabled}
          tabIndex={tabIndex}
          data-gramm="false"
          data-gramm_editor="false"
          data-enable-grammarly="false"
          rows={1}
          ref={textAreaRef}
        />

        {/* <label
          htmlFor={id}
          className={`${
            required && "required-field"
          } absolute left-4 top-[10px] bg-transparent px-0.5 text-xs font-normal text-coal-light transition-all peer-placeholder-shown:top-5 peer-placeholder-shown:text-base ${
            value ? `top-[10px] font-medium` : `cursor-text`
          }`}>
          {placeholder}
        </label> */}
      </div>
      {error?.status && error.message && (
        <div className="mt-1 flex items-center gap-1 ">
          <AlertCircle className="-mt-[1px] h-3 w-3 shrink-0 text-ouch" />
          <label
            htmlFor={id}
            className="bg-transparent text-xs font-normal text-ouch transition-all peer-focus:hidden">
            {error.message}
          </label>
        </div>
      )}
      {iconComponent}
    </div>
  );
};

export default TextArea;
