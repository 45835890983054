import OTPInput from "components/auth/OTPInput";
import GenericButton from "components/common/buttons/GenericButton";
import { OTPChannel } from "lib/types/user";
import React, { useEffect, useState } from "react";
import smsLogo from "assests/images/sms-otp.svg";
import { useLocale } from "lib/hooks/useLocale";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import { Lock } from "react-feather";
import ShopfloLogo from "assests/images/flo-full-logo-mono.svg";
import whatsappLogo from "assests/images/whatsapp-otp.svg";

interface OTPFormProps {
  isLoading: boolean;
  otpLength: number;
  otpValue: string;
  handleSubmit: any;
  handleChange: (a: string) => void;
  validateOtp: (otp: string) => Promise<void>;
  handleResendOtp: (channel: OTPChannel) => Promise<void>;
  otpCounter: number;
  otpCounterExpired: boolean;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  headerComponent?: React.ReactNode;
  emailValidation: boolean;
  showLoginConsent?: boolean;
  context?: "cod" | "auth" | "sso" | "cart";
  showSecuredByShopflo?: boolean;
  disableResend?: boolean;
}

const OTPForm: React.FC<OTPFormProps> = ({
  handleSubmit,
  otpValue,
  handleChange,
  validateOtp,
  handleResendOtp,
  error,
  errorMessage,
  isLoading,
  otpLength,
  disabled = false,
  autoFocus = true,
  otpCounter,
  otpCounterExpired,
  headerComponent,
  emailValidation = false,
  showLoginConsent = false,
  context = "auth",
  showSecuredByShopflo = false,
  disableResend = false,
}) => {
  const { t } = useLocale();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const [loginConsent, setLoginConsent] = useState(true);
  const isAuth = context === "auth" || context === "sso";
  const isCart = context === "cart";
  const isSso = context === "sso";

  useEffect(() => {
    if (otpValue.length === otpLength) {
      validateOtp(otpValue);
    }
  }, [otpValue]);

  return (
    <>
      {headerComponent}
      <form onSubmit={() => handleSubmit(validateOtp)} className="flex w-full flex-col items-center ">
        <OTPInput
          OTPLength={4}
          disabled={disabled || disableResend}
          value={otpValue}
          onChange={handleChange}
          hasError={(error && !isLoading) || false}
          autoFocus={autoFocus}
          inputType={"tel"}
          disableTheme={context === "sso"}
        />

        <p
          className={`${showLoginConsent && !isCart ? "my-3" : "mt-3"} text-xs font-normal ${
            error && !isLoading ? "text-ouch" : "text-coal-light"
          }`}>
          {error && !isLoading ? errorMessage : null}
          {!error && showLoginConsent && !isCart ? t("otp_incorrect_login") : null}
          {!error && !showLoginConsent && isAuth ? t("otp_incorrect") : null}
          {!error && !showLoginConsent && context === "cod" ? t("enter_otp_to_place_order") : null}
        </p>
        {showLoginConsent && (
          <div
            className="flex cursor-pointer items-center gap-1 text-xs font-medium text-coal-light"
            onClick={() => {
              sendAnalyticsEvent({
                eventName: loginConsent
                  ? analyticsEvents.FLO_COOKIE_LOGIN_DISABLED
                  : analyticsEvents.FLO_COOKIE_LOGIN_ENABLED,
                eventType: "click",
              });
              setLoginConsent(!loginConsent);
            }}>
            <input
              type="checkbox"
              id="login_user_consent"
              name="login_user_consent"
              className="h-3 w-3 cursor-pointer accent-coal-dark"
              checked={loginConsent}
              readOnly
            />
            <label htmlFor="login_user_consent">{t("keep_logged_in")}</label>
          </div>
        )}

        <div className={`${context === "cod" ? "mt-2" : "mt-4"} mb-2 flex w-full flex-col items-center`}>
          {
            <div className={context !== "cod" ? "px-3 pb-3" : !otpCounterExpired ? "py-3" : ""}>
              <p className="text-sm font-normal text-coal-light">
                {otpCounter > 0 && !disableResend
                  ? `${t("resend_otp_timer")} 00:${otpCounter < 10 ? `0${otpCounter}` : otpCounter}`
                  : null}
                {otpCounterExpired && emailValidation ? "" : null}
                {otpCounterExpired && !emailValidation && (isAuth || isCart)
                  ? `${t("resend_otp_via")}`
                  : null}
              </p>
            </div>
          }
          {otpCounterExpired && (
            <>
              {emailValidation && (
                <>
                  <GenericButton
                    buttonText={t("resend_otp")}
                    isDisabled={disableResend}
                    className="flex items-center justify-center gap-1 rounded-lg border p-2 text-sm font-normal text-coal-dark"
                    onClick={(e) => {
                      e.preventDefault();
                      handleResendOtp("EMAIL");
                    }}
                  />
                </>
              )}
              {!emailValidation && (isAuth || isCart) && (
                <div className="flex gap-2">
                  <GenericButton
                    buttonText={t("send_via_sms")}
                    isDisabled={disableResend}
                    className="flex items-center justify-center gap-1 rounded-lg border p-2 text-sm font-normal"
                    iconComponent={<img src={smsLogo} alt="SMS Logo" />}
                    onClick={(e) => {
                      e.preventDefault();
                      handleResendOtp("SMS");
                    }}
                  />
                  {!isSso && (
                    <GenericButton
                      buttonText={t("send_via_whatsapp")}
                      isDisabled={disableResend}
                      className="flex items-center justify-center gap-1 rounded-lg border p-2 text-sm font-normal"
                      iconComponent={<img src={whatsappLogo} alt="Whatsapp Logo" />}
                      onClick={(e) => {
                        e.preventDefault();
                        handleResendOtp("WHATSAPP");
                      }}
                    />
                  )}
                </div>
              )}
              {!emailValidation && context === "cod" && (
                <div>
                  <GenericButton
                    buttonText={t("resend_otp")}
                    isDisabled={disableResend}
                    className="my-4 flex items-center justify-center gap-1 rounded-lg border p-2 text-sm font-normal text-coal-dark"
                    onClick={(e) => {
                      e.preventDefault();
                      handleResendOtp("SMS");
                    }}
                  />
                </div>
              )}
            </>
          )}
          {context === "cod" && (
            <p className="mb-4 mt-2 text-center text-xs text-coal-dark">{t("cod_otp_footer_message")}</p>
          )}
          {showSecuredByShopflo && (
            <div className="mt-2 flex flex-row items-center space-x-1">
              <Lock className="h-3 w-3 text-coal-light" strokeWidth={3} />
              <span className="text-[10px] font-normal text-coal-light">Secured by </span>
              <a href="https://shopflo.com" className="font-bold" target="_blank" rel="noreferrer">
                <img src={ShopfloLogo} className="h-3" alt={"shopflo-logo"} />
              </a>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default OTPForm;
