import Cart from "pages/cart";
import React, { lazy } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import MainCheckout from "pages/main-checkout";

const PaymentStatus = lazy(() => import("../../pages/payment-status.tsx"));
const LoginSSO = lazy(() => import("../../pages/login-sso.tsx"));
const LoginCheck = lazy(() => import("../../pages/login-check.tsx"));
const GhostModal = lazy(() => import("../../pages/ghost.tsx"));

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = ({}) => {
  return (
    <>
      <Routes>
        {/* Multiple route paths are not supported on Cloudflare. Hence avoid it. */}
        <Route path={`/payment-status`} element={<PaymentStatus />} />
        <Route path={`/`} element={<Routing />} />
      </Routes>
    </>
  );
};

const Routing: React.FC<AppRouterProps> = ({}) => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  switch (page) {
    case "payment-status":
      return <PaymentStatus />;
    case "login-sso":
      return <LoginSSO />;
    case "cart":
    case "CART":
      return <Cart />;
    case "login-status-check":
      return <LoginCheck />;
    case "ghost-modal":
      return <GhostModal />;
    default:
      return <MainCheckout />;
  }
};

export default AppRouter;
