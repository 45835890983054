import { XIcon } from "@heroicons/react/solid";
import Logout from "components/checkout/dialogs/Logout";
import LogoutConfirmation from "components/checkout/dialogs/LogoutConfirmtaion";
import GenericButton from "components/common/buttons/GenericButton";
import { DialogBody, DialogHeader } from "components/common/dialogs/GenericDialog";
import { useLocale } from "lib/hooks/useLocale";
import React, { useState } from "react";

interface LogoutDialogProps {
  setIsOpen: (value: boolean) => void;
  handleLogout: () => void;
}

const LogoutDialog: React.FC<LogoutDialogProps> = ({ setIsOpen, handleLogout }) => {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  return (
    <>
      {showLogoutConfirmation ? (
        <LogoutConfirmation setIsOpen={setIsOpen} handleLogout={handleLogout} />
      ) : (
        <Logout
          setIsOpen={setIsOpen}
          handleLogout={handleLogout}
          setShowLogoutConfirmation={setShowLogoutConfirmation}
        />
      )}
    </>
  );
};

export default LogoutDialog;
