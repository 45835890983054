import { DialogBody, DialogHeader, GenericDialog } from "components/common/dialogs/GenericDialog";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import React from "react";

interface LoaderDialogProps {
  title: string;
}

const LoaderDialog: React.FC<LoaderDialogProps> = ({ title }) => {
  const {
    state: { checkoutModal },
  } = useCheckoutContext();
  return (
    <GenericDialog
      isOpen={checkoutModal === "LOADER"}
      translateAxis="y"
      customClass="overflow-scroll md:!top-auto md:absolute"
      dialogOverlay={true}
      modalType="PROCESSING">
      <DialogHeader>
        <div className="flex h-full w-full flex-row items-center justify-between">
          <h1 className="text-base font-medium"> {title}</h1>
        </div>
      </DialogHeader>
      <DialogBody>
        <div className="h-[5px] w-full overflow-hidden bg-carbon-lighter">
          <div className="progress left-right h-full w-full bg-primary-dark"></div>
        </div>
      </DialogBody>
    </GenericDialog>
  );
};

export default LoaderDialog;
