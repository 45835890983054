import { useMerchantContext } from "lib/contexts/MerchantProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { TrustBadgeConfig } from "lib/types/merchant";
import { analyticsEvents } from "lib/utils/constants";
import { trustBadgeImgConfig } from "lib/utils/customBranding";
import React from "react";

interface TrustBadgesProps {}

const TrustBadges: React.FC<TrustBadgesProps> = () => {
  const {
    state: { merchant },
  } = useMerchantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  if (!Boolean(merchant?.trustBadgeConfig?.showBadges)) return <></>;

  return (
    <div className="!m-0 flex w-full items-center justify-center gap-4 py-3">
      {merchant?.trustBadgeConfig?.badges.map((badge: TrustBadgeConfig, index: number) => {
        return (
          <div
            className="justify-top flex h-20 w-20 flex-col items-center gap-[5.5px]"
            onClick={() => {
              sendAnalyticsEvent({
                eventName: analyticsEvents.FLO_TRUST_BADGE_CLICKED,
                eventType: "click",
                metaData: { badgesData: { badge: badge.label } },
              });
            }}>
            <div>
              <img src={trustBadgeImgConfig[badge?.label]} alt={badge?.label} />
            </div>
            <div className="w-full text-center text-xxs text-gray-dark">{badge.text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default TrustBadges;
