import React, { ChangeEvent, useEffect, useMemo, useState, useCallback } from "react";
import { Dialog } from "@headlessui/react";
import { DialogHeader, DialogBody, DialogFooter } from "components/common/dialogs/GenericDialog";
import { AlertCircle, X, Check } from "react-feather";
import CheckCircle from "assests/images/check-circle.svg";
import { AddressFields, AddressPayloadType, ErrorType } from "lib/types/address";
import { postRequest } from "lib/core/apiClient";
import { useUserContext } from "lib/contexts/UserProvider";
import { useLocale } from "lib/hooks/useLocale";
import { putRequest, getRequest } from "lib/core/apiClient";
import InputField from "components/common/forms/InputField";
import {
  getDetailsByZip,
  getAddressConfigByCountryCode,
  getProvincesByCountryCode,
} from "lib/core/apiMethods";
import {
  addDefaultCountryCode,
  capitalizeFirstCharacter,
  inIframe,
  isEmptyObj,
  redirectUrl,
} from "lib/utils/helpers";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { errorToast } from "lib/utils/toasters";
import { parseUserData } from "lib/utils/user";
import { getItems } from "lib/utils/checkout";
import { OTPVerificationDetails, UserType } from "lib/types/user";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { AddressSource, analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import PhoneInput, { Value as PhoneInputValueType } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DropDown from "components/common/fields/DropDown";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import TextArea from "components/common/forms/TextArea";
import { ShippingHandlesType, CheckoutActions } from "lib/types/checkout";
import useForm, { FormField, ValidationSchema } from "lib/hooks/useForm";
import { getAddressFields } from "lib/utils/address";
import GenericBannerStrip from "components/common/notification-strips/GenericBannerStrip";
import { sendOtpWithPhoneNumber } from "lib/utils/auth";
import SectionTitle from "components/common/SectionTitle";
import Cookies from "js-cookie";
import { constants } from "lib/utils/constants";
import { useSearchParams } from "react-router-dom";
import MarketingConsent from "components/common/MarketingConsent";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { mutate } from "swr";
import useReCaptcha from "lib/hooks/useReCaptcha";

interface AddressFormProps {
  addressId: string;
  initialFormErrors?: Record<string, ErrorType | undefined>;
}
interface AddressTypeProps {
  value?: string;
  id: string;
  label: string;
  checked?: boolean;
  isDisabled: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({ addressId, initialFormErrors }) => {
  const [searchParams] = useSearchParams();
  const { t } = useLocale();
  const {
    state: { user },
    actions: { setUserData },
  } = useUserContext();
  const {
    state: {
      checkoutId,
      isTwoStepCheckout,
      checkoutToken,
      shopifySessionId,
      originUrl,
      checkoutUIMetadata,
      actionUrls,
      initialCheckoutStep,
      checkoutValidations,
    },
    actions: {
      setShippingHandles,
      setCheckoutView,
      updateCheckoutBasedOnCheckoutResponse,
      setCheckoutModal,
      setOOSItems,
      setCheckoutValidations,
    },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const { generateReCaptchaToken } = useReCaptcha();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const [isNewAddress, setIsNewAddress] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stateDisabled, setStateDisabled] = useState<boolean>(false);
  const [editAddressKeys, setEditAddressKeys] = useState<string[]>([]);
  const [showOthersInput, setShowOthersInput] = useState<boolean>(false);
  const [phoneInputFocused, setPhoneInputFocused] = useState<boolean>(false);
  const [alternateNumberFocused, setAlternateNumberFocused] = useState<boolean>(false);
  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [zipError, setZipError] = useState<{ status: boolean; message: string }>({
    status: false,
    message: "",
  });
  const [fieldsConfig, setFieldsConfig] = useState<any[]>([]);
  const [addressConsent, setAddressConsent] = useState<boolean>(true);
  const [alternateNumberEnabled, setAlternateNumberEnabled] = useState<boolean>(false);

  const validationSchema: ValidationSchema = useMemo(() => {
    if (!merchant) return {} as ValidationSchema;
    const schema = getAddressFields(fieldsConfig, states, editAddressKeys, alternateNumberEnabled);
    return schema;
  }, [fieldsConfig, editAddressKeys, merchant, states, alternateNumberEnabled]);

  const initialState: AddressFields = isTwoStepCheckout
    ? {
        zip: user?.default_shipping_address?.zip ?? "",
        address1: user?.default_shipping_address?.address1 ?? "",
        address2: user?.default_shipping_address?.address2 ?? "",
        name: capitalizeFirstCharacter(user?.default_shipping_address?.name ?? ""),
        phone: user?.default_shipping_address?.phone ?? "",
        city: user?.default_shipping_address?.city ?? "",
        state: user?.default_shipping_address?.state ?? "",
        state_code: user?.default_shipping_address?.state_code ?? "",
        country: user?.default_shipping_address?.country ?? "India",
        country_code: user?.default_shipping_address?.country_code ?? "IN",
        type: "Home",
        email: user?.default_shipping_address?.email?.trim() ?? "",
        alternate_number: user?.default_shipping_address?.alternate_number ?? "",
      }
    : {
        zip: "",
        address1: "",
        address2: "",
        name: capitalizeFirstCharacter(user?.name ?? ""),
        phone: user?.phone ?? "",
        city: "",
        state: "",
        state_code: "",
        country: "India",
        country_code: "IN",
        type: "Home",
        email: user?.email?.trim() ?? "",
        alternate_number: "",
      };

  const {
    handleSubmit,
    inputProps,
    setValues,
    setErrors,
    setValueOf,
    handleFieldChange,
    state: { values, errors, validations },
  } = useForm({ initialState, validationSchema, initialErrorState: initialFormErrors });

  const addressTypes: AddressTypeProps[] = [
    {
      value: "HOME",
      id: "home_opt",
      label: t("home"),
      isDisabled: Boolean(user?.hasHomeAddr),
    },
    {
      value: "WORK",
      id: "work_opt",
      label: t("work"),
      isDisabled: Boolean(user?.hasWorkAddr),
    },
    {
      value: "OTHER",
      id: "other_opt",
      label: t("other"),
      isDisabled: false,
    },
  ];

  useEffect(() => {
    sendAnalyticsEvent({
      eventName:
        !Boolean(addressId) || Boolean(addressId === "ADDRESS_NEW")
          ? analyticsEvents.FLO_ADDRESS_NEW_FORM_LOADED
          : analyticsEvents.FLO_ADDRESS_EDIT_FORM_LOADED,
      eventType: "load",
    });
    if (isNewAddress && !isTwoStepCheckout) {
      handleInternationalAddresses();
      handleCountrySelection("IN");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Boolean(addressId) || Boolean(addressId === "ADDRESS_NEW")) {
      setIsNewAddress(true);
      return;
    }
    setIsNewAddress(false);
    getAddressById(addressId);
  }, [addressId]);

  useEffect(() => {
    if (values?.country_code === "IN" && values?.phone !== "" && values?.phone?.substring(0, 3) !== "+91") {
      setAlternateNumberEnabled(true);
      return;
    }
    setAlternateNumberEnabled(false);
  }, [values?.country_code, values?.phone]);

  const isAddressBannerEnabled = useCallback(() => {
    const filteredData = checkoutUIMetadata?.bannerConfig?.filter(
      (config: any) => config.type === "ORDER_SUMMARY" && config?.enabled,
    );
    return Boolean(filteredData?.length);
  }, [checkoutUIMetadata]);

  const getAddressById = async (addressId: string) => {
    if (!Boolean(addressId)) return;

    try {
      setIsLoading(true);
      const response: any = await getRequest(`/attributes/v1/address/${addressId}`);
      const promises = [
        handleCountrySelection(response?.data?.country_code ?? "IN", Boolean(initialFormErrors)),
      ];

      if (!Boolean(response?.data?.country_code) && response?.data?.country?.toUpperCase() === "INDIA") {
        response.data.country_code = "IN";
      }
      if (response?.data?.country_code !== "IN") {
        promises.push(handleInternationalAddresses());
      }

      if (response?.data?.country_code === "IN" && !Boolean(response?.data?.phone?.startsWith("+"))) {
        response.data.phone = `+91${response?.data?.phone}`;
      }
      await Promise.all(promises);
      const addressKeys = Object.keys(response.data).filter((key) => Boolean(response.data[key]));
      setEditAddressKeys(addressKeys);
      let phoneNumber = response?.data?.phone ?? user?.phone;
      phoneNumber = phoneNumber?.replaceAll(" ", "");
      setValues({
        zip: response?.data?.zip,
        address1: response?.data?.address1,
        address2: response?.data?.address2,
        name: capitalizeFirstCharacter(response?.data?.name ?? ""),
        phone: phoneNumber,
        city: response?.data?.city,
        state: capitalizeFirstCharacter(response?.data?.state ?? ""),
        state_code: response?.data?.state_code,
        country: response?.data?.country,
        country_code: response?.data?.country_code,
        type: response?.address_type?.trim() || "Other",
        email: response?.data?.email?.trim() ?? user?.email?.trim(),
        alternate_number: response?.data?.alternate_number?.replaceAll(" ", ""),
      });
      setIsLoading(false);
      if (
        response?.address_type?.toUpperCase() !== "HOME" &&
        response?.address_type?.toUpperCase() !== "WORK" &&
        response?.address_type?.toUpperCase() !== "OTHER"
      ) {
        setShowOthersInput(true);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const handleZipChange = async (zip: string) => {
    const countryCode = values?.country_code;
    if (countryCode === "IN" && Boolean(/\D/.test(zip))) return; //Checks for special characters
    handleFieldChange(FormField.zip, zip);
    let zipDetails: any = {};

    try {
      if (Boolean(zip) && countryCode === "IN" && zip?.length === 6) {
        zipDetails = await getDetailsByZip(zip);
        setZipError({ status: false, message: t("valid_pincode") });
        setValues((oldValues: any) => ({
          ...oldValues,
          city: capitalizeFirstCharacter(zipDetails.district),
          state: capitalizeFirstCharacter(zipDetails.state),
          state_code: zipDetails.state_code,
          country: capitalizeFirstCharacter(zipDetails.country),
        }));
        setStateDisabled(true);
      }

      setErrors((oldValues) => ({
        ...oldValues,
        city: { ...oldValues.city, status: false },
        state: { ...oldValues.state, status: false },
        country: { ...oldValues.country, status: false },
        state_code: { ...oldValues.state_code, status: false },
      }));
    } catch (e) {
      if (merchant?.addressConfig?.disable_unavailable_pincode) {
        setZipError({ status: true, message: t("valid_pincode") });
        return;
      }

      if (isEmptyObj(zipDetails)) {
        setStateDisabled(false);
        setValues((oldValues) => ({
          ...oldValues,
          state: "",
          state_code: "",
        }));
        return;
      }
    }
  };

  const onDialogFormSubmit = async () => {
    if (Boolean(zipError?.status)) return;
    const payload: AddressPayloadType = {
      account_id: user?.uid,
      source: AddressSource.SHIPPING_ADDRESS,
      address_type: values.type?.trim() || "OTHER",
      data: {
        ...values,
        address1: values.address1?.replace(/<[^>]*>?/gm, "")?.trim(),
        address2: values.address2?.replace(/<[^>]*>?/gm, "")?.trim(),
        name: values.name.trim(),
        city: values.city.trim(),
        email: values.email?.trim(),
        zip: values.zip?.trim(),
      },
    };
    try {
      setIsLoading(true);
      let response;
      if (isNewAddress) {
        response = await postRequest(`/checkout/v1/checkout/${checkoutId}/address`, payload);
      } else if (Boolean(addressId)) {
        response = await putRequest(`/checkout/v1/checkout/${checkoutId}/address/${addressId}`, payload);
      } else {
        setCheckoutView("ADDRESS_LIST");
        setIsLoading(false);
      }
      const accountResponse = await getRequest("/gatekeeper/v1/accounts?include=address,attributes");
      handleResponseSuccess(response, accountResponse);
      sendAnalyticsEvent({
        eventName: addressConsent
          ? analyticsEvents.FLO_ADDRESS_CONSENT_ENABLED
          : analyticsEvents.FLO_ADDRESS_CONSENT_DISABLED,
        eventType: "click",
      });
      setIsLoading(false);

      if (response?.metadata?.error_response?.code === "OUT_OF_STOCK") {
        const oosItems = response?.metadata?.error_response?.metadata?.items;
        setOOSItems(getItems(oosItems, true) ?? []);
        setCheckoutModal("OOS");
        return;
      }

      const isAddressServiceable = response?.pricing?.serviceable ?? false;
      if (!Boolean(isAddressServiceable)) {
        errorToast(t("serviceability_error"), 5000);
        return;
      }

      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      const shippingHandles = response?.metadata?.available_shipping_handles ?? [];
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles) && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
        return;
      }

      if (response?.metadata?.action_urls?.[CheckoutActions.ADDRESS_SELECT]?.success_url) {
        redirectUrl(response.metadata.action_urls[CheckoutActions.ADDRESS_SELECT].success_url);
        return;
      }

      if (
        actionUrls &&
        actionUrls[CheckoutActions.ADDRESS_SELECT] &&
        actionUrls[CheckoutActions.ADDRESS_SELECT].success_url
      ) {
        redirectUrl(actionUrls[CheckoutActions.ADDRESS_SELECT].success_url);
        return;
      }

      if (initialCheckoutStep === "PAYMENTS") {
        setCheckoutModal("NONE");

        setCheckoutValidations({
          ...checkoutValidations,
          address: {
            isValid: true,
          },
        });
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      }

      setCheckoutModal("NONE");
      setCheckoutView("PAYMENTS");
    } catch (e: any) {
      const errorMsg = e?.response?.data?.error;
      //need to change this based on backend(key value pair of error keys)
      if (errorMsg === "Please enter a valid E-mail to proceed") {
        setErrors((oldValues) => ({
          ...oldValues,
          email: { status: true, message: t("valid_email") },
        }));
        setIsLoading(false);
        return;
      }
      errorToast(errorMsg ?? t("add_address_failed"));
      setCheckoutView("ADDRESS_LIST");
      setIsLoading(false);
      if (
        actionUrls &&
        actionUrls[CheckoutActions.ADDRESS_SELECT] &&
        actionUrls[CheckoutActions.ADDRESS_SELECT].failure_url
      ) {
        redirectUrl(actionUrls[CheckoutActions.ADDRESS_SELECT].failure_url);
      }
    }
  };

  const onTwoStepFormSubmit = async () => {
    let authToken;
    let expiry;
    let checkoutResponse;
    try {
      setIsLoading(true);
      let verificationDetails: OTPVerificationDetails = {
        identifier: addDefaultCountryCode(values?.phone),
        merchantId: merchant?.merchantId ?? "",
        skipVerification: true,
        domain: inIframe() ? originUrl : constants?.CHECKOUT_HOST,
      };
      const reCaptchaToken = await generateReCaptchaToken("login");
      const result = await sendOtpWithPhoneNumber(verificationDetails, { context: "AUTH" }, reCaptchaToken);
      authToken = result.token?.access_token;
      expiry = result?.access_token_expires_at;
      Cookies.set(constants.TWO_STEP_AUTH_COOKIE_CLIENT, authToken, {
        expires: expiry,
        sameSite: "none",
        secure: true,
      });
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_GUEST_LOGIN,
        eventFor: [analyticsTypes.SF_ANALYTICS],
        eventType: "flo_action",
        metaData: {
          merchantId: merchant?.merchantId,
          checkoutToken: checkoutToken,
          checkoutId: checkoutId,
          shopifySessionId: shopifySessionId,
          userData: {
            type: analyticsEvents.FLO_GUEST_LOGIN,
          },
        },
      });
      if (Boolean(user?.uid)) {
        const tokenId = searchParams.get("tokenId");
        const response = await postRequest(`/checkout/v2/checkout-tokens/${tokenId}/checkout`, {});
        checkoutResponse = await putRequest(`/checkout/v2/checkout`, { uid: response?.checkout?.uid });
      } else {
        checkoutResponse = await putRequest(`/checkout/v2/checkout`, { uid: checkoutId });
      }

      const payload = {
        account_id: checkoutResponse?.checkout?.customer_id,
        address_type: values.type?.trim() || "OTHERS",
        data: {
          ...values,
          address1: values.address1?.trim(),
          address2: values.address2?.trim(),
          name: values.name.trim(),
          city: values.city.trim(),
          email: values.email?.trim(),
        },
      };
      const response = await postRequest(`/checkout/v1/checkout/${checkoutId}/address`, payload);
      const accountResponse = await getRequest("/gatekeeper/v1/accounts?include=address,attributes");
      handleResponseSuccess(response, accountResponse);
      setIsLoading(false);
      //Phone entered event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_AUTH_PHONE_ENTERED,
        eventType: "click",
        metaData: {
          userType: "guest",
        },
      });

      const isAddressServiceable = response?.pricing?.serviceable ?? false;
      if (!Boolean(isAddressServiceable)) {
        errorToast(t("serviceability_error"), 5000);
        return;
      }

      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      const shippingHandles = response?.metadata?.available_shipping_handles ?? [];
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles) && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
        return;
      }
      if (initialCheckoutStep === "PAYMENTS") {
        setCheckoutModal("NONE");
      }
      setCheckoutView("PAYMENTS");
    } catch (e: any) {
      const errorMsg = e?.response?.data?.error;
      //need to change this based on backend(key value pair of error keys)
      if (errorMsg === "Please enter a valid E-mail to proceed") {
        setErrors((oldValues) => ({
          ...oldValues,
          email: { status: true, message: t("valid_email") },
        }));
        setIsLoading(false);
        return;
      }
      errorToast(errorMsg ?? t("add_address_failed"));
      setIsLoading(false);
      console.error(e);
    }
  };

  const handleResponseSuccess = (checkoutResponse: any, accountResponse: any) => {
    handleCheckoutResponse(checkoutResponse);
    handleAccountResponse(accountResponse, checkoutResponse);
    sendAnalyticsEvent({
      eventName: isNewAddress
        ? analyticsEvents.FLO_ADDRESS_NEW_FORM_SUBMITTED
        : analyticsEvents.FLO_ADDRESS_EDIT_FORM_SUBMITTED,
      eventType: "click",
    });
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse);
  };

  const handleAccountResponse = (accountResponse: any, checkoutResponse: any) => {
    const parsedUserData: UserType = parseUserData(accountResponse, checkoutResponse);
    setUserData(parsedUserData);
  };

  const handleInternationalAddresses = async () => {
    try {
      setIsLoading(true);
      const countriesResp = await getRequest("/v1/countries", "CHECKOUT_PUBLIC");
      if (!Boolean(countriesResp?.length)) {
        return;
      }
      setCountries(countriesResp);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCountrySelection = async (countryCode: string, hasErrors?: boolean) => {
    if (!Boolean(countryCode)) return;
    try {
      setIsLoading(true);
      const response = await Promise.allSettled([
        getProvincesByCountryCode(countryCode),
        getAddressConfigByCountryCode(countryCode, merchant?.merchantId ?? ""),
      ]);

      if (!response.length) return;

      if (response[1]?.status === "fulfilled") {
        const addressConfigResponse = response[1].value?.config?.fields;
        setFieldsConfig(addressConfigResponse);
      }

      if (response[0]?.status === "fulfilled") {
        const provincesResp = response[0].value;
        setValues((oldValues: any) => ({
          ...oldValues,
          zip: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          state_code: "",
        }));
        setErrors((oldValues) => ({
          ...oldValues,
          ...(hasErrors
            ? initialFormErrors
            : {
                zip: { ...oldValues.zip, status: false },
                address1: { ...oldValues.address1, status: false },
                address2: { ...oldValues.address2, status: false },
                city: { ...oldValues.city, status: false },
                state: { ...oldValues.state, status: false },
                country: { ...oldValues.country, status: false },
                state_code: { ...oldValues.state_code, status: false },
              }),
        }));
        setStateDisabled(false);
        if (!Boolean(provincesResp?.length)) {
          setStates([]);
          setValues((oldValues: any) => ({
            ...oldValues,
            state_code: " ",
          }));
          setIsLoading(false);
          return;
        }
        provincesResp.unshift({ code: "", name: "" });
        setStates(provincesResp);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getZipError = useCallback(() => {
    if (zipError.status) {
      return zipError;
    }
    if (errors.zip?.status) {
      return errors.zip;
    }
    return { status: false };
  }, [zipError, errors]);

  const renderAddressForm = () => {
    return (
      <form
        className={`flex flex-col space-y-4 pt-4`}
        id="address-form"
        onSubmit={isTwoStepCheckout ? handleSubmit(onTwoStepFormSubmit) : handleSubmit(onDialogFormSubmit)}>
        <fieldset>
          <SectionTitle title={t("address_contact_details")} customClass={`mb-6 uppercase !text-xxs -mx-5`} />
          <div className="flex flex-col space-y-2">
            {Boolean(validationSchema?.phone) && (
              <div id={`flo-field-${FormField.phone}`} className={`relative flex flex-col`}>
                <label
                  htmlFor={FormField.phone}
                  className={`required-field mb-0.5 text-xxs uppercase text-gray-dark`}>
                  {t("phone_number")}
                </label>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  defaultCountry="IN"
                  country={values?.country_code}
                  countries={merchant?.addressConfig?.enabledCountries}
                  className={`placeholder-sm peer h-14 w-full appearance-none rounded-xl border px-3 text-base placeholder-transparent focus:border-0 focus:outline-none focus:ring-1 focus-visible:outline-none ${
                    errors.phone ? "border-ouch" : "border-gray-light"
                  } ${Boolean(values.phone?.length) && !errors?.phone?.status ? "border-[#D5E7DB]" : ""} ${
                    phoneInputFocused &&
                    "border-[1px] !border-primary-dark px-[12px] outline-none ring-[2px] ring-primary-light"
                  }`}
                  placeholder={t("phone_number")}
                  {...inputProps(FormField.phone, {
                    onChange: (value: PhoneInputValueType) => {
                      handleFieldChange(FormField.phone, value);
                    },
                    onBlur: (e: any) => setPhoneInputFocused(false),
                  })}
                  onFocus={(e) => setPhoneInputFocused(true)}
                  error={errors.phone?.status}
                  limitMaxLength
                />
                <div className="absolute right-3 top-9">
                  {validations?.phone && <img src={CheckCircle} className="h-4 w-4" alt="Check Icon" />}
                </div>

                {errors.phone?.status && errors.phone.message && (
                  <div className="mt-1 flex items-center gap-0.5 ">
                    <AlertCircle className="-mt-[1px] h-3 w-3 cursor-pointer text-ouch" />
                    <label
                      htmlFor={"phone"}
                      className="bg-transparent text-xs font-normal text-ouch transition-all peer-focus:hidden">
                      {errors.phone?.message}
                    </label>
                  </div>
                )}
              </div>
            )}

            <MarketingConsent customClass="!p-0 !mt-2 h-6 !justify-start" />

            {Boolean(validationSchema?.name) && (
              <InputField
                type="text"
                label={t("full_name")}
                filled={Boolean(values.name?.length)}
                autoComplete="name"
                {...inputProps(FormField.name)}
                error={errors.name}
                required={Boolean(validationSchema?.name?.required)}
                showSuccessIcon={validations?.name}
              />
            )}

            {Boolean(validationSchema?.email) && (
              <InputField
                type="text"
                label={t("email")}
                {...inputProps(FormField.email)}
                filled={Boolean(values.email?.length)}
                autoComplete="email"
                error={errors.email}
                required={Boolean(validationSchema?.email?.required)}
                showSuccessIcon={validations?.email}
              />
            )}
          </div>
        </fieldset>

        <fieldset className="flex flex-col space-y-2">
          <SectionTitle title={t("address")} customClass={`uppercase !text-xxs -mx-5 `} />
          {/* <div className="my-2">
            {merchant?.addressConfig?.isInternationalAddressEnabled && !isInternationalAddr && (
              <p className={` mb-4 text-xxs text-coal-light`}>
                Not ordering for India?{" "}
                <span className="underline cursor-pointer font-xs" onClick={handleInternationalAddresses}>
                  Click here
                </span>
              </p>
            )}
          </div> */}

          {Boolean(validationSchema?.alternate_number && alternateNumberEnabled) && (
            <div id={`flo-field-${FormField.alternate_number}`} className={`relative flex flex-col`}>
              <label
                htmlFor={"alternate_number"}
                className={`required-field mb-0.5 text-xxs uppercase text-gray-dark`}>
                {validationSchema?.alternate_number?.label}
              </label>
              <PhoneInput
                international={true}
                countryCallingCodeEditable={false}
                defaultCountry="IN"
                country={"IN"}
                countries={["IN"]}
                className={`placeholder-sm peer h-14 w-full appearance-none rounded-xl border px-3 text-base placeholder-transparent focus:border-0 focus:outline-none focus:ring-1 focus-visible:outline-none ${
                  errors.alternate_number ? "border-ouch" : "border-gray-light"
                } ${
                  Boolean(values.alternate_number?.length) && !errors?.alternate_number?.status
                    ? "border-[#D5E7DB]"
                    : ""
                } ${
                  alternateNumberFocused &&
                  "border-[1px] !border-primary-dark px-[12px] outline-none ring-[2px] ring-primary-light"
                }`}
                placeholder={t("phone_number")}
                {...inputProps(FormField.alternate_number, {
                  onChange: (value: any) => {
                    handleFieldChange(FormField.alternate_number, value);
                  },
                  onBlur: (e: any) => setAlternateNumberFocused(false),
                })}
                onFocus={(e) => setAlternateNumberFocused(true)}
                error={errors.alternate_number?.status}
                limitMaxLength
              />
              <div className="absolute right-3 top-9">
                {validations?.phone && <img src={CheckCircle} className="h-4 w-4" alt="Check Icon" />}
              </div>

              {errors.alternate_number?.status && errors.alternate_number.message && (
                <div className="mt-1 flex items-center gap-0.5 ">
                  <AlertCircle className="-mt-[1px] h-3 w-3 cursor-pointer text-ouch" />
                  <label
                    htmlFor={"alternate_number"}
                    className="bg-transparent text-xs font-normal text-ouch transition-all peer-focus:hidden">
                    {errors.alternate_number?.message}
                  </label>
                </div>
              )}
            </div>
          )}

          {merchant?.addressConfig?.isInternationalAddressEnabled && (
            <DropDown
              label={t("country_region")}
              {...inputProps(FormField.country_code, {
                onChange: (e: any) => {
                  setValueOf("country_code", e?.target?.value);
                  setValueOf("country", e?.target?.selectedOptions[0]?.text);
                  handleCountrySelection(e?.target?.value);
                },
              })}
              autoComplete="shipping country"
              options={countries}
              optionKey="code"
              optionValue="name"
              required={Boolean(validationSchema?.country?.required)}
              error={errors.country_code}
            />
          )}

          {Boolean(validationSchema?.address1) && (
            <TextArea
              label={validationSchema?.address1?.label}
              placeholder={t("address1")}
              {...inputProps(FormField.address1)}
              filled={Boolean(values.address1?.length)}
              autoComplete={Boolean(validationSchema?.address1) ? "address-line1" : "shipping street-address"}
              error={errors.address1}
              required={Boolean(validationSchema?.address1?.required)}
              showSuccessIcon={validations?.address1}
            />
          )}
          {Boolean(validationSchema?.address2) && (
            <TextArea
              label={validationSchema?.address2?.label}
              {...inputProps(FormField.address2)}
              filled={Boolean(values.address2?.length)}
              autoComplete={Boolean(validationSchema?.address2) ? "address-line2" : "shipping street-address"}
              error={errors.address2}
              required={Boolean(validationSchema?.address2?.required)}
              showSuccessIcon={validations?.address2}
            />
          )}

          {Boolean(validationSchema?.zip) && (
            <InputField
              type={values.country_code === "IN" ? "tel" : "text"}
              label={t("address_pincode")}
              maxLength={values.country_code === "IN" ? 6 : 25}
              filled={Boolean(values.zip?.length)}
              autoComplete="none"
              {...inputProps(FormField.zip, {
                onChange: (e: any) => {
                  handleZipChange(e.target.value);
                },
              })}
              //TODO: Clean this up
              error={getZipError()}
              required={Boolean(validationSchema?.zip?.required)}
              showSuccessIcon={validations?.zip}
            />
          )}

          <div className="flex w-full flex-row items-start space-x-2">
            {Boolean(validationSchema?.city) && (
              <InputField
                type="text"
                label={t("address_city")}
                {...inputProps(FormField.city)}
                filled={Boolean(values.city?.length)}
                autoComplete="shipping address-level2"
                error={errors.city}
                required={Boolean(validationSchema?.city?.required)}
                showSuccessIcon={validations?.city}
              />
            )}

            {Boolean(validationSchema?.state) &&
              (states?.length ? (
                <DropDown
                  label={t("address_state")}
                  {...inputProps(FormField.state_code, {
                    onChange: (e: any) => {
                      setValueOf("state_code", e?.target?.value);
                      setValueOf("state", e?.target?.selectedOptions[0]?.text);
                      Boolean(errors.state) && delete errors.state;
                    },
                  })}
                  autoComplete="shipping address-level1"
                  disabled={stateDisabled}
                  options={states}
                  optionKey="code"
                  optionValue="name"
                  required={Boolean(validationSchema?.state?.required)}
                  error={errors.state}
                />
              ) : (
                <InputField
                  type="text"
                  label={t("address_state")}
                  {...inputProps(FormField.state)}
                  filled={Boolean(values.state?.length)}
                  autoComplete="shipping address-level1"
                  error={errors.state}
                  required={Boolean(validationSchema?.state?.required)}
                  showSuccessIcon={validations?.state}
                />
              ))}
          </div>
        </fieldset>

        {!Boolean(isTwoStepCheckout) && (
          <fieldset>
            <SectionTitle title={t("save_address_as")} customClass={`uppercase !text-xxs -mx-5 mb-6`} />
            <div className="flex flex-col space-y-4">
              {showOthersInput ? (
                <div className="relative w-full">
                  {isNewAddress ? (
                    <input
                      className={`placeholder-sm peer h-12 w-full appearance-none rounded-xl border bg-white px-3 text-base focus:border focus:border-primary-dark focus:px-4 focus:outline-none focus:ring-2 focus:ring-primary-light ${
                        errors?.type && Boolean(errors?.type?.status) && Boolean(errors?.type?.message)
                          ? "border-ouch"
                          : ""
                      }`}
                      type="text"
                      maxLength={25}
                      autoFocus={isNewAddress}
                      {...inputProps(FormField.type)}
                      placeholder="ex: Simpson's Home"
                    />
                  ) : (
                    <input
                      className={`placeholder-sm peer h-12 w-full appearance-none rounded-xl border bg-white px-3 text-base focus:border focus:border-primary-dark focus:px-4 focus:outline-none focus:ring-2 focus:ring-primary-light ${
                        errors?.type && Boolean(errors?.type?.status) && Boolean(errors?.type?.message)
                          ? "border-ouch"
                          : ""
                      }`}
                      type="text"
                      maxLength={25}
                      autoFocus={false}
                      {...inputProps(FormField.type)}
                      placeholder="ex: Simpson's Home"
                    />
                  )}

                  <X
                    className="absolute right-4 top-3 h-6 w-6 cursor-pointer text-coal-dark"
                    onClick={() => {
                      if (!Boolean(values?.type)) {
                        setValueOf("type", "Other");
                      }
                      setShowOthersInput(false);
                    }}
                  />
                  {errors?.type && Boolean(errors?.type?.status) && Boolean(errors?.type?.message) && (
                    <div className="mt-1 flex items-center gap-1 ">
                      <AlertCircle className="-mt-[1px] h-3 w-3 shrink-0 text-ouch" />
                      <span className="bg-transparent text-xs font-normal text-ouch transition-all peer-focus:hidden">
                        {errors?.type?.message}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <ul className="flex flex-row space-x-2">
                  {addressTypes.map((type: AddressTypeProps) => (
                    <li
                      onClick={() => {
                        if (type?.value === "OTHER") {
                          setShowOthersInput(true);
                          if (
                            values?.type?.toLowerCase() === "work" ||
                            values?.type?.toLowerCase() === "home"
                          ) {
                            setValueOf("type", "");
                          }
                          return;
                        }
                        setValueOf("type", type?.value);
                      }}
                      key={type.id}>
                      <AddressType
                        value={values?.type}
                        id={type.id}
                        label={type.label}
                        isDisabled={type.isDisabled}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </fieldset>
        )}

        <div
          className="flex cursor-pointer items-center gap-1 text-xs font-medium text-coal-light"
          onClick={() => {
            setAddressConsent(!addressConsent);
          }}>
          <input
            type="checkbox"
            id="save_address_user_consent"
            name="save_address_user_consent"
            className="h-3 w-3 cursor-pointer accent-coal-dark"
            checked={addressConsent}
            readOnly
          />
          <label htmlFor="save_address_user_consent">{t("save_address_checkout")}</label>
        </div>

        {Boolean(isTwoStepCheckout) ? (
          <DialogFooter>
            <PrimaryButton type="submit" buttonText={t("proceed_to_pay")} height="h-14" isCheckout={true} />
          </DialogFooter>
        ) : (
          <div className="mt-4">
            <PrimaryButton
              type="submit"
              id="flo_address_submit"
              buttonText={t("save_and_continue")}
              height="h-14"
              isCheckout={true}
            />
          </div>
        )}
      </form>
    );
  };

  return (
    <React.Fragment>
      {isTwoStepCheckout ? (
        <>{renderAddressForm()}</>
      ) : (
        <>
          <DialogHeader className={`${isAddressBannerEnabled() ? "!bg-white" : ""}`}>
            <Dialog.Title as="div" className={`flex h-full w-full flex-row items-center justify-between`}>
              <h1 className="text-base font-medium text-coal-light">
                {isNewAddress ? t("add_new_address_header") : t("edit_address")}
              </h1>
              <button className="outline-none">
                <X
                  className="h-6 w-6 cursor-pointer text-coal-light"
                  onClick={() => {
                    sendAnalyticsEvent({
                      eventName: analyticsEvents.FLO_BACK_CLICK,
                      eventType: "click",
                    });
                    setCheckoutModal("NONE");
                    if (initialCheckoutStep !== "PAYMENTS") setCheckoutView("ADDRESS_LIST");
                  }}
                />
              </button>
            </Dialog.Title>
          </DialogHeader>
          <DialogBody className="!pt-12">
            <GenericBannerStrip bannerType="ORDER_SUMMARY" />
            <div className="px-6">{renderAddressForm()}</div>
          </DialogBody>
        </>
      )}

      {isLoading && <OverlaySpinner />}
    </React.Fragment>
  );
};

const AddressType: React.FC<AddressTypeProps> = ({ value, id, label, isDisabled }) => {
  const selectedAddressType = () => {
    switch (value?.toUpperCase()) {
      case "HOME":
        return label.toUpperCase() === "HOME";
      case "WORK":
        return label.toUpperCase() === "WORK";
      default:
        return label.toUpperCase() === "OTHER";
    }
  };
  return (
    <div className="relative">
      <label
        id={id}
        className={`flex h-12 max-h-12 w-24 min-w-max max-w-full cursor-pointer items-center justify-center rounded-xl border text-base font-normal text-coal-dark ${
          isDisabled && "cursor-not-allowed opacity-40"
        } ${
          selectedAddressType()
            ? "border-0 font-medium ring-1 ring-primary-dark"
            : `hover:border-[1.5px] hover:bg-gray-lighter`
        }`}
        htmlFor={id}>
        {selectedAddressType() && <Check className="mr-1.5 h-4 w-4 text-coal-dark" />}
        {label}
      </label>
    </div>
  );
};

export default AddressForm;
