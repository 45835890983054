import React, { useEffect, useRef, useState } from "react";
import { ShippingHandleType, CheckoutActions } from "lib/types/checkout";
import { useLocale } from "lib/hooks/useLocale";
import { putRequest } from "lib/core/apiClient";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { getDefaultShippingHandle } from "lib/utils/checkout";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { mutate } from "swr";
import { analyticsEvents } from "lib/utils/constants";
import Price from "components/common/Price";
import { capitalizeFirstCharacter, redirectUrl, truncateString } from "lib/utils/helpers";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import Accordion from "components/common/Accordian";
import { ArrowRight, Truck } from "react-feather";
import { useUserContext } from "lib/contexts/UserProvider";

interface ShippingHandlesSectionProps {
  loadPayments: () => void;
}

const ShippingHandlesSection: React.FC<ShippingHandlesSectionProps> = ({ loadPayments }) => {
  const { t } = useLocale();
  const {
    state: { shippingHandles, checkoutId, checkoutView, actionUrls, checkoutUIMetadata, activeComponent },
    actions: {
      updateCheckoutBasedOnCheckoutResponse,
      setShippingHandles,
      setActiveComponent,
      setCheckoutModal,
    },
  } = useCheckoutContext();
  const {
    state: { user },
  } = useUserContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const [selectedHandle, setSelectedHandle] = useState<ShippingHandleType>({
    id: "",
    handle_name: "",
    price: "",
    selected_handle: false,
    cod_enabled: false,
    cod_extra_price: "0",
    online_enabled: true,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const shippingAccordionRef = useRef<any>(null);

  useEffect(() => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_SHIPPING_HANDLES_LOADED,
      eventType: "load",
      metaData: {
        shippingData: {
          availableHandles: shippingHandles,
        },
      },
    });
    const defaultShippingHandle: ShippingHandleType | undefined = getDefaultShippingHandle(shippingHandles);
    if (defaultShippingHandle) {
      onHandleSelect(defaultShippingHandle, true);
    }
    if (!Boolean(defaultShippingHandle)) {
      loadPayments();
    }
  }, []);

  useEffect(() => {
    if (activeComponent === "SHIPPING_SECTION") {
      shippingAccordionRef.current.openAccordion();
      shippingAccordionRef.current.scrollIntoView();
    }
    return () => {
      setActiveComponent("NONE");
    };
  }, [activeComponent]);

  useEffect(() => {
    if (!Boolean(shippingHandles.length)) {
      return;
    }
    const defaultShippingHandle: ShippingHandleType | undefined = getDefaultShippingHandle(shippingHandles);
    if (defaultShippingHandle) {
      setSelectedHandle(defaultShippingHandle);
    }
  }, [shippingHandles]);

  const onHandleSelect = async (handle: ShippingHandleType, initialLoad: boolean = false) => {
    if (!handle.id) return;
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_SHIPPING_HANDLE_SELECTED,
      eventType: "click",
      metaData: {
        shippingData: {
          availableHandles: shippingHandles,
          selectedHandle: handle,
          isCod: false,
        },
      },
    });

    await updateShippingHandle(handle, initialLoad);
    shippingAccordionRef.current.closeAccordion();
    if (initialLoad) loadPayments();
    return;
  };

  const updateShippingHandle = async (handle: ShippingHandleType, initialLoad: boolean = false) => {
    const payload = {
      shipping_handle: handle.id,
    };

    try {
      setIsLoading(true);
      const response = await putRequest(`/checkout/v1/checkout/${checkoutId}/shipping-handle`, payload);
      handleCheckoutResponse(response);
      const selectedShippingHandle: ShippingHandleType | undefined = getDefaultShippingHandle(
        response?.metadata?.available_shipping_handles,
      );
      if (selectedShippingHandle) {
        setSelectedHandle(selectedShippingHandle);
      }

      if (checkoutView === "PAYMENTS" && !initialLoad) {
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        if (Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled))
          mutate(`/checkout/v1/checkout/${checkoutId}/upsell`);
        mutate(`UPI_INTENT`);
        return;
      }

      if (response?.metadata?.action_urls?.[CheckoutActions.ADDRESS_SELECT]?.success_url) {
        redirectUrl(response.metadata.action_urls[CheckoutActions.ADDRESS_SELECT].success_url);
        return;
      }

      if (
        actionUrls &&
        actionUrls[CheckoutActions.ADDRESS_SELECT] &&
        actionUrls[CheckoutActions.ADDRESS_SELECT].success_url
      ) {
        redirectUrl(actionUrls[CheckoutActions.ADDRESS_SELECT].success_url);
        return;
      }
    } catch (e) {
      console.error(e);
      if (
        actionUrls &&
        actionUrls[CheckoutActions.ADDRESS_SELECT] &&
        actionUrls[CheckoutActions.ADDRESS_SELECT].failure_url
      ) {
        redirectUrl(actionUrls[CheckoutActions.ADDRESS_SELECT].failure_url);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse, true);
    const updatedHandles = checkoutResponse?.metadata?.available_shipping_handles ?? [];
    if (Boolean(updatedHandles?.length)) {
      setShippingHandles(updatedHandles);
    }
  };

  if (!Boolean(user?.addresses?.length) || !Boolean(shippingHandles.length)) return <></>;

  return (
    <>
      <Accordion
        id={"shipping-handles-section"}
        ref={shippingAccordionRef}
        defaultOpen={false}
        titleIcon={<Truck className="h-4 w-4 text-coal-dark" strokeWidth={2.5} />}
        titleText={
          <div className="flex items-center space-x-2">
            <h2 className="text-sm font-medium text-coal-dark">{t("shipping")}</h2>
            <div className="flex w-fit rounded-xl bg-gray-lighter px-2 py-0.5">
              <p className="w-full px-[3px] text-left text-sm font-medium text-coal-light">
                {truncateString(
                  capitalizeFirstCharacter(selectedHandle?.handle_name ? selectedHandle?.handle_name : ""),
                  12,
                )}
              </p>
            </div>
          </div>
        }
        subTitleText={
          selectedHandle?.price === "0.00" || selectedHandle?.price === "0.0" ? (
            <p className="text-sm font-normal uppercase text-yay-dark">{t("free_shipping")}</p>
          ) : (
            <p className="text-sm font-medium uppercase text-coal-dark">{"+ ₹" + selectedHandle?.price}</p>
          )
        }
        customClass="!mt-0"
        content={
          <div className="pb-4">
            <ul className="flex flex-col gap-3">
              {shippingHandles.map((handle) => (
                <li
                  key={handle?.id}
                  onClick={(e) => {
                    e.preventDefault();
                    onHandleSelect(handle);
                  }}>
                  <Handle
                    id={handle?.id}
                    name={`${handle.handle_name}`}
                    charge={handle.price}
                    isChecked={selectedHandle.id === handle?.id}
                    etd={handle.etd}
                    codEnabled={handle.cod_enabled}
                    codExtraPrice={parseInt(handle.cod_extra_price ?? "0")}
                    onlineEnabled={handle.online_enabled}
                  />
                </li>
              ))}
            </ul>
          </div>
        }
      />

      {isLoading && <OverlaySpinner />}
    </>
  );
};

interface HandleProps {
  id: string;
  name: string;
  charge: string;
  isChecked: boolean;
  codEnabled: boolean;
  codExtraPrice: number;
  etd?: string;
  onlineEnabled: boolean;
}

export const Handle: React.FC<HandleProps> = React.memo(
  ({ id, name, charge, isChecked, codEnabled, codExtraPrice, etd, onlineEnabled }) => {
    const { t } = useLocale();
    const {
      state: { checkoutView, isC2P },
    } = useCheckoutContext();

    charge = parseFloat(charge)?.toFixed(2);

    const getCodTag = () => {
      if (!Boolean(codEnabled)) return <></>;

      if (!Boolean(onlineEnabled)) {
        return (
          <>
            <div
              className={`mt-2 flex w-fit items-center rounded-2xl bg-gray-lighter  px-2 py-1 text-xs font-medium text-gray-dark`}>
              <p>COD only</p>
            </div>
          </>
        );
      }

      if (Boolean(checkoutView === "PAYMENTS") && !Boolean(isC2P)) {
        return (
          <>
            <div
              className={`mt-2 flex w-fit items-center rounded-2xl bg-yay-light px-2 py-1 text-xs font-medium text-yay-dark`}>
              <p>
                COD available
                {Boolean(codExtraPrice) && <span> (with additional charges)</span>}
              </p>
            </div>
          </>
        );
      }

      return <></>;
    };

    return (
      <>
        <label
          className={`${
            isChecked ? "border-[1.5px] border-primary-dark px-[11.5px] py-[11.5px]" : "border-gray-light"
          } relative mx-3 flex h-full min-h-[4rem] cursor-pointer flex-row items-start justify-between rounded-2xl border px-3 py-3`}>
          <span
            className={`flex h-4 w-4 min-w-[1rem] !items-center !justify-center rounded-full border-2 ${
              isChecked ? "border-primary-dark" : "border-gray-light"
            }`}>
            <span className={`h-2 w-2 ${isChecked ? "bg-primary-dark" : "bg-none"} rounded-full`}></span>
          </span>
          <div className="flex w-full flex-col justify-center pl-3">
            <div className="flex w-full flex-row">
              <p className="grow text-sm text-coal-dark">{name}</p>
              {charge === "0.00" ? (
                <p className="text-sm font-medium uppercase text-coal-dark">{t("free_shipping")}</p>
              ) : (
                <p className="flex items-center justify-center gap-1 text-sm font-medium text-coal-dark">
                  + <Price total={parseFloat(charge) ?? ""} />
                </p>
              )}
            </div>
            {Boolean(etd) && <p className="pt-1 text-xs text-coal-light">{etd}</p>}

            {getCodTag()}
          </div>
        </label>
      </>
    );
  },
);

export default ShippingHandlesSection;
