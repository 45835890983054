import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface GenericPopupProps {
  content: JSX.Element;
  isOpen: boolean;
  closePopup: () => void;
  height: string;
  width: string;
  duration: number;
}

const GenericPopup: React.FC<GenericPopupProps> = ({
  content,
  isOpen = false,
  closePopup,
  height,
  width,
  duration = 2000,
}) => {
  useEffect(() => {
    Boolean(isOpen) &&
      setTimeout(() => {
        closePopup();
      }, duration);
  }, [isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={closePopup}>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div
                className={`inline-block transform overflow-auto rounded-lg bg-white p-6 align-middle shadow-xl transition-all ${height} ${width}`}>
                {content}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default GenericPopup;
