import { classNames } from "lib/utils/helpers";
import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { ChevronDown } from "react-feather";

interface AccordionProps {
  id: string;
  titleIcon: JSX.Element;
  titleText: React.ReactNode;
  subTitleText?: React.ReactNode;
  content: React.ReactNode;
  defaultOpen?: boolean;
  callbackOnToggle?: (isOpen: boolean) => void;
  customClass?: string;
  buttonClass?: string;
  insideClass?: string;
}

export interface AccordionRefType {
  openAccordion: () => void;
  closeAccordion: () => void;
  scrollIntoView: () => void;
}

const Accordion = forwardRef<AccordionRefType, AccordionProps>(
  (
    {
      id,
      titleIcon,
      titleText,
      content,
      defaultOpen = false,
      subTitleText,
      callbackOnToggle,
      customClass,
      buttonClass,
      insideClass,
    },
    ref,
  ) => {
    const [active, setActive] = useState(defaultOpen);

    useEffect(() => {
      if (!defaultOpen && active) {
        setActive(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultOpen]);

    function toggleAccordion() {
      setActive((prevState) => !prevState);
      if (callbackOnToggle) {
        callbackOnToggle(!active);
      }
    }

    useImperativeHandle(ref, () => ({
      openAccordion() {
        setActive(true);
      },

      closeAccordion() {
        setActive(false);
      },

      scrollIntoView() {
        const section = document.querySelector(`#${id}`);
        if (section) {
          section.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      },
    }));

    return (
      <div
        id={id}
        className={`border-base mx-3 mt-3 flex flex-col bg-white ${
          active ? "shadow-[0px_0px_10px_0px_rgba(0,0,0,0.05)] duration-200 ease-in-out" : "delay-[100ms]"
        } ${customClass}`}>
        <button
          className={classNames(
            `flex h-[3.25rem] w-full cursor-pointer flex-row items-center rounded-[inherit] px-3 py-4 focus:outline-none `,
            buttonClass ?? "",
          )}
          onClick={toggleAccordion}>
          <div className={`flex w-full flex-row items-center justify-between ${insideClass}`}>
            {Boolean(titleText) && (
              <div className="flex flex-row items-center space-x-2">
                {titleIcon}
                <p className="text-sm font-medium text-coal-dark">{titleText}</p>
              </div>
            )}
            <div className="flex flex-row items-center gap-2">
              {Boolean(subTitleText) && <p className="text-sm text-coal-light">{subTitleText}</p>}

              <ChevronDown
                className={`h-4 w-4 text-coal-dark ${
                  active
                    ? `rotate-180 transform duration-700 ease-in-out`
                    : `rotate-0 transform duration-700 ease-in-out`
                }`}
              />
            </div>
          </div>
        </button>
        <div
          className={`overflow-hidden transition-[max-height] ${!active && "hidden"} ${
            active ? `duration-700 ease-in` : `duration-500 ease-out`
          } ${active ? `max-h-[9999px]` : ` max-h-0`} `}>
          {content}
        </div>
      </div>
    );
  },
);

export default Accordion;
