import React, { useState } from "react";
import { DialogBody, DialogFooter } from "components/common/dialogs/GenericDialog";
import { useLocale } from "lib/hooks/useLocale";
import NotPlacedImg from "assests/images/order-failed.svg";
import CheckoutItems from "components/checkout/CheckoutItems";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { putRequest } from "lib/core/apiClient";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { errorToast } from "lib/utils/toasters";
import { getItems } from "lib/utils/checkout";
import { CheckoutActions, ShippingHandlesType } from "lib/types/checkout";
import { redirectUrl } from "lib/utils/helpers";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { mutate } from "swr";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface OOSDialogProps {}

const OOSDialog: React.FC<OOSDialogProps> = () => {
  const { t } = useLocale();
  const {
    state: { merchant },
  } = useMerchantContext();

  const {
    state: { oosItems, checkoutId, isC2P, actionUrls },
    actions: {
      setShippingHandles,
      setCheckoutView,
      setCheckoutModal,
      setCheckoutItems,
      updateCheckoutBasedOnCheckoutResponse,
    },
  } = useCheckoutContext();
  const {
    state: { isAuthenticated },
  } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOOSClick = async () => {
    try {
      setIsLoading(true);
      const response = await putRequest(`/checkout/v2/checkout/${checkoutId}/checkout-items`, {});
      mutate(
        !Boolean(isC2P)
          ? Boolean(checkoutId) && Boolean(isAuthenticated)
            ? `/checkout/v1/checkout/${checkoutId}/discounts`
            : `/v1/checkout/${checkoutId}/discounts`
          : null,
      );
      const items = getItems(response.items);
      setCheckoutItems(items);
      handleCheckoutResponse(response);
      if (!Boolean(response?.pricing?.serviceable)) {
        errorToast(t("serviceability_error"), 5000);
        setCheckoutView("ADDRESS_LIST");
        return;
      }
      setIsLoading(false);

      const isAddressServiceable = response?.pricing?.serviceable ?? false;
      if (!Boolean(isAddressServiceable)) {
        errorToast(t("serviceability_error"), 5000);
        return;
      }

      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      const shippingHandles = response?.metadata?.available_shipping_handles ?? [];
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles)) {
        setCheckoutModal("SHIPPING_HANDLES");
        return;
      }
      if (response?.metadata?.action_urls?.[CheckoutActions.ADDRESS_SELECT]?.success_url) {
        redirectUrl(response.metadata.action_urls[CheckoutActions.ADDRESS_SELECT].success_url);
        return;
      }

      if (
        actionUrls &&
        actionUrls[CheckoutActions.ADDRESS_SELECT] &&
        actionUrls[CheckoutActions.ADDRESS_SELECT].success_url
      ) {
        redirectUrl(actionUrls[CheckoutActions.ADDRESS_SELECT].success_url);
        return;
      }
      setCheckoutModal("NONE");
      setCheckoutView("PAYMENTS");
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setCheckoutModal("NONE");
      //Exit checkout if items go OOS
      if (merchant) {
        redirectUrl(`${merchant.brandUrl}/?floRedirectStatus=oos`);
      }
    }
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse);
  };

  if (!Boolean(oosItems?.length)) return <></>;
  return (
    <>
      <DialogBody className="!px-3 !pt-9">
        <div className="flex flex-col items-center">
          <img src={NotPlacedImg} alt="oos_leaf" className="h-10 w-10" />
          <h1 className="pt-6 text-xl font-medium text-carbon-dark">{t("oos_body_header")}</h1>
          <p className="pt-2 pb-3 text-sm text-coal-dark">{t("oos_body_subheader")}</p>
          {Boolean(oosItems) && <CheckoutItems items={oosItems ?? []} disableOOSItems={true} />}
        </div>
      </DialogBody>

      <DialogFooter>
        <PrimaryButton
          buttonText={t("oos_cta")}
          onClick={handleOOSClick}
          height="h-14 mb-12"
          isCheckout={false}
        />
      </DialogFooter>

      {isLoading && <OverlaySpinner />}
    </>
  );
};

export default OOSDialog;
