import React from "react";
import { XIcon } from "@heroicons/react/solid";
import GenericButton from "components/common/buttons/GenericButton";
import { DialogBody, DialogHeader } from "components/common/dialogs/GenericDialog";
import { useLocale } from "lib/hooks/useLocale";
import { analyticsEvents } from "lib/utils/constants";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";

interface LogoutConfirmationProps {
  setIsOpen: (value: boolean) => void;
  handleLogout: () => void;
}

const LogoutConfirmation: React.FC<LogoutConfirmationProps> = ({ setIsOpen, handleLogout }) => {
  const { t } = useLocale();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  return (
    <>
      <DialogHeader>
        <div className="flex w-full items-center justify-between text-base">
          <h1 className="font-medium text-carbon-dark">{t("logout_confirmation_dialog_header")}</h1>
          <button className="outline-none">
            <XIcon className="h-6 w-6 cursor-pointer text-coal-dark" onClick={() => setIsOpen(false)} />
          </button>
        </div>
      </DialogHeader>
      <DialogBody>
        <div className="mx-6 flex flex-col items-center gap-6 ">
          <div className="flex flex-col gap-4 text-sm font-normal text-gray-dark">
            <p>{t("logout_confirmation_dialog_info1")}</p>
            <p>{t("logout_confirmation_dialog_info2")}</p>
          </div>
          <div className="flex w-full flex-col gap-3">
            <GenericButton
              buttonText={t("okay")}
              height="h-14"
              customClass="bg-primary-dark rounded-[12px] text-btnPrimaryText font-semibold"
              onClick={() => {
                sendAnalyticsEvent({
                  eventName: analyticsEvents.FLO_EXIT_LOGOUT_CLICKED,
                  eventType: "click",
                });
                setIsOpen(false);
              }}
            />
            <GenericButton
              buttonText={t("logout")}
              height="h-14"
              customClass="border-primary-dark  border rounded-[12px] text-primary-dark font-semibold"
              onClick={handleLogout}
            />
          </div>
          <a
            className="cursor-pointer text-sm font-medium text-coal-light underline"
            href="https://shopflo.com/privacy-policy?utm_source=shopflo-checkout&utm_medium=shopflo-checkout&utm_campaign=shopflo-checkout"
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              sendAnalyticsEvent({
                eventName: analyticsEvents.FLO_PRIVACY_POLICY,
                eventType: "click",
              })
            }>
            {t("logout_confirmation_dialog_footer")}
          </a>
        </div>
      </DialogBody>
    </>
  );
};

export default LogoutConfirmation;
