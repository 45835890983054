import React, { ChangeEvent, useEffect, useMemo, useState, useCallback } from "react";
import { Dialog } from "@headlessui/react";
import {
  DialogHeader,
  DialogBody,
  DialogFooter,
  GenericDialog,
} from "components/common/dialogs/GenericDialog";
import { X } from "react-feather";
import { useUserContext } from "lib/contexts/UserProvider";
import { useLocale } from "lib/hooks/useLocale";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import "react-phone-number-input/style.css";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import GenericBannerStrip from "components/common/notification-strips/GenericBannerStrip";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import GenericButton from "components/common/buttons/GenericButton";
import AddressModify from "components/address/dialogs/AddressModify";
import AddressForm from "components/address/dialogs/AddressForm";
import { PlusCircleIcon } from "@heroicons/react/outline";

interface AddressListDialogProps {
  setIsOpen?: () => void;
  isPrimaryBtnLoading: boolean;
}

const AddressListDialog: React.FC<AddressListDialogProps> = ({ setIsOpen, isPrimaryBtnLoading }) => {
  const { t } = useLocale();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const [editAddressId, setEditAddressId] = useState<string>("ADDRESS_NEW");
  const [openAddrForm, setOpenAddrForm] = useState<boolean>(false);

  const handleEditAddress = (id: string) => {
    setEditAddressId(id);
    setOpenAddrForm(true);
  };

  return (
    <>
      <DialogHeader className={`!bg-white`}>
        <Dialog.Title as="div" className={`flex h-full w-full flex-row items-center justify-between`}>
          <h1 className="text-base font-medium text-carbon-dark">{t("delivery")}</h1>
          <button className="outline-none">
            <X
              className="h-6 w-6 cursor-pointer text-carbon-dark"
              onClick={() => {
                sendAnalyticsEvent({
                  eventName: analyticsEvents.FLO_BACK_CLICK,
                  eventType: "click",
                });
                if (setIsOpen) setIsOpen();
              }}
            />
          </button>
        </Dialog.Title>
      </DialogHeader>
      <DialogBody className="scrollbar-hide !pt-12">
        <GenericBannerStrip bannerType="ORDER_SUMMARY" />
        <div className="flex flex-col space-y-4 px-4 pb-12 pt-4">
          <GenericButton
            height="h-12"
            width="w-full"
            id="flo_add__address"
            customClass="text-sm font-medium text-coal-dark space-x-2 font-medium border-[1px] border-gray-light rounded-2xl px-3 flex items-center justify-center hover:ring-gray-light hover:ring-[2px]"
            buttonText={t("add_new_address_button")}
            iconComponent={<PlusCircleIcon className="h-5 w-5 text-yay-dark outline-none" />}
            onClick={() => {
              setEditAddressId("ADDRESS_NEW");
              setOpenAddrForm(true);
            }}
          />
          <AddressModify handleEditAddress={handleEditAddress} isPrimaryBtnLoading={isPrimaryBtnLoading} />
        </div>
      </DialogBody>
      <GenericDialog
        isOpen={openAddrForm}
        setIsOpen={setOpenAddrForm}
        translateAxis="y"
        dialogOverlay={true}
        customClass="h-[92%] md:h-[77vh] md:top-[8vh]"
        modalType="ADDRESS_NEW">
        <AddressForm addressId={editAddressId} />
      </GenericDialog>
    </>
  );
};

export default AddressListDialog;
