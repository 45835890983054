import React from "react";
import { AutocompleteAttribute } from "lib/types/fields";
import { AlertCircle, ChevronDown } from "react-feather";
import { ErrorType } from "lib/types/address";

interface DropDownProps {
  label?: string;
  placeholder?: string;
  id: string;
  name: string;
  autoComplete?: AutocompleteAttribute;
  value?: string;
  required?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  customClass?: string;
  customContainerClass?: string;
  error?: ErrorType;
  disabled?: boolean;
  options?: Array<any>;
  optionKey?: string;
  optionValue?: string;
}

const DropDown: React.FC<DropDownProps> = ({
  label,
  placeholder,
  id,
  name,
  autoComplete,
  value = "",
  required = false,
  onChange,
  customClass,
  customContainerClass,
  error = { status: false, message: "", showAlert: true },
  disabled = false,
  options = [],
  optionKey = "key",
  optionValue = "value",
  onBlur,
  onFocus,
}) => {
  return (
    <div id={`flo-field-${id}`} className={`flex w-full flex-col ${customContainerClass}`}>
      {Boolean(label) && (
        <label
          htmlFor={id}
          className={`${required ? "required-field" : ""} mb-0.5  text-xxs uppercase text-gray-dark`}>
          {label}
        </label>
      )}
      <div className={`relative flex h-14 w-full flex-col`}>
        <div>
          <select
            id={id}
            name={name}
            placeholder={placeholder}
            className={`placeholder-sm absolute inset-0 z-10 h-14 w-full appearance-none truncate rounded-xl border !bg-transparent px-3 pr-10 text-base text-charcoal placeholder-transparent transition-none focus:border-[1px] focus:border-primary-dark focus:px-[12px] focus:outline-none focus:ring-[2px] focus:ring-primary-light ${customClass} peer text-sm ${
              error.status ? " border-ouch" : ""
            } ${disabled ? "border !border-gray-light bg-gray-lighter text-gray-dark" : ""} ${
              Boolean(value?.length) && !error?.status ? "border-[#D5E7DB]" : ""
            }`}
            autoComplete={autoComplete}
            aria-label={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            disabled={disabled}>
            {options.map((option) => {
              return (
                <option key={option[optionKey]} value={option[optionKey]}>
                  {option[optionValue]}
                </option>
              );
            })}
          </select>
        </div>

        <ChevronDown className={`pointer-events-none absolute right-4 top-5 h-4 w-4 text-coal-dark`} />
      </div>
      {error?.status && Boolean(error?.message) && (
        <div className="mt-1 flex items-center gap-1 ">
          <AlertCircle className="-mt-[1px] h-3 w-3 shrink-0 text-ouch" />
          <label
            htmlFor={id}
            className="bg-transparent text-xs font-normal text-ouch transition-all peer-focus:hidden">
            {error.message}
          </label>
        </div>
      )}
    </div>
  );
};

export default DropDown;
