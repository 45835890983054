import React, { useEffect } from "react";
import MultiFactorAuthentication from "components/auth/MultiFactorAuthentication";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface UnauthenticatedViewsProps {}

const UnauthenticatedViews: React.FC<UnauthenticatedViewsProps> = ({}) => {
  const {
    actions: { setCheckoutView },
  } = useCheckoutContext();
  useEffect(() => {
    setCheckoutView("AUTH");
  }, []);

  return (
    <div className="flex w-full flex-col items-center space-y-4 px-3 pt-3 pb-4">
      <MultiFactorAuthentication />
    </div>
  );
};

export default UnauthenticatedViews;
