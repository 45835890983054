import React from "react";

interface SelectionTitleProps {
  title: string;
  align?: "left" | "right" | "center";
  customClass?: string;
}

const SectionTitle: React.FC<SelectionTitleProps> = ({ title, customClass, align = "left" }) => {
  return (
    <div
      className={`flex items-center ${customClass ?? ""} ${
        align === "left" ? "justify-start" : align === "right" ? "justify-end" : "justify-center"
      }`}>
      <div className={`h-[1px]  w-3.5 bg-gray-light ${align === "right" ? "flex-grow" : ""}`}></div>
      <p className={`mx-2 flex-grow-0`}>{title.toUpperCase()}</p>
      <div className={`h-[1px] w-3.5 bg-gray-light ${align === "left" ? "flex-grow" : ""}`}></div>
    </div>
  );
};

export default SectionTitle;
