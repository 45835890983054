import React, { useEffect, useState } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { ExitSurveyOptionType } from "lib/types/checkout";
import {
  DialogHeader,
  DialogBody,
  DialogFooter,
  GenericDialog,
} from "components/common/dialogs/GenericDialog";
import { ArrowLeft } from "react-feather";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { postSurvey } from "lib/core/apiClient";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { errorToast } from "lib/utils/toasters";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface SurveyPopupProps {
  isOpen: boolean;
  closePopup: () => void;
  closeCheckout: () => void;
}

const SurveyPopup: React.FC<SurveyPopupProps> = ({ isOpen, closePopup, closeCheckout }) => {
  const { t } = useLocale();
  const {
    state: { checkoutId, exitSurveyOptions },
  } = useCheckoutContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>();
  const [otherOptionMessage, setOtherOptionMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) return;
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_SURVEY_FORM_LOADED,
      eventType: "load",
    });
  }, [isOpen]);

  const handleSubmit = async () => {
    if (selectedOptionIndex === undefined) {
      errorToast(t("select_reason"));
      return;
    }
    setIsLoading(true);

    const payload = {
      rank: selectedOptionIndex + 1,
      drop_off_reason: exitSurveyOptions[selectedOptionIndex].optionId,
      drop_off_answer:
        selectedOptionIndex === exitSurveyOptions.length - 1
          ? otherOptionMessage
          : exitSurveyOptions[selectedOptionIndex].optionLabel,
    };

    try {
      await postSurvey(payload, checkoutId);
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_SURVEY_FORM_ANSWERED,
        eventType: "click",
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      closeCheckout();
    }
  };

  const handleSkip = () => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_SURVEY_FORM_SKIPPED,
      eventType: "click",
    });
    closeCheckout();
  };

  return (
    <>
      <GenericDialog
        isOpen={isOpen}
        setIsOpen={() => closePopup()}
        translateAxis="y"
        customClass={`overflow-scroll md:!top-auto md:absolute h-full rounded-t-2xl ${
          selectedOptionIndex === exitSurveyOptions.length - 1 ? "max-h-[80vh]" : "max-h-[72vh]"
        }`}
        dialogOverlay={true}
        modalType="EXIT_SURVEY">
        <DialogHeader>
          <div className="flex h-full w-full flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-2">
              <ArrowLeft className="h-5 w-5 cursor-pointer" onClick={closePopup} />
              <h1 className="text-base font-medium"> {t("why_cancel_question")} </h1>
            </div>
            <button className="text-carbon text-sm font-medium" onClick={handleSkip}>
              {t("skip")}
            </button>
          </div>
        </DialogHeader>
        <DialogBody>
          <ul className="flex flex-col gap-2 px-3">
            {exitSurveyOptions.map((surveyOption: ExitSurveyOptionType, index: number) => {
              if (surveyOption.optionId === "others") {
                return (
                  <li
                    key={surveyOption.optionId}
                    className={`border-1 cursor-pointer rounded-xl border border-gray-light bg-white ${
                      selectedOptionIndex === index
                        ? "border-transparent shadow-[0_0_10px_rgba(0,0,0,0.1)] ring-[2px] ring-primary-dark  hover:ring-primary-dark"
                        : "border-gray-light hover:ring-gray-light"
                    }`}
                    onClick={() => setSelectedOptionIndex(index)}>
                    <p className={`p-3 text-coal-dark`}>
                      {surveyOption.optionLabel} {selectedOptionIndex === index && " (Type Below)"}
                    </p>
                    {selectedOptionIndex === index && (
                      <div className="border-t border-gray-light p-3">
                        <input
                          type="text"
                          placeholder="Type your response"
                          value={otherOptionMessage}
                          onChange={(e: any) => setOtherOptionMessage(e.target.value)}
                          className="w-full outline-none"
                        />
                      </div>
                    )}
                  </li>
                );
              }
              return (
                <li
                  key={surveyOption.optionId}
                  className={`border-1 cursor-pointer rounded-xl border border-gray-light bg-white p-3 ${
                    selectedOptionIndex === index
                      ? "border-transparent shadow-[0_0_10px_rgba(0,0,0,0.1)] ring-[2px] ring-primary-dark  hover:ring-primary-dark"
                      : "border-gray-light hover:ring-gray-light"
                  }`}
                  onClick={() => setSelectedOptionIndex(index)}>
                  <p className="text-coal-dark">{surveyOption.optionLabel}</p>
                </li>
              );
            })}
          </ul>
        </DialogBody>
        <DialogFooter>
          <PrimaryButton
            buttonText={t("submit")}
            id="flo_survey_submit"
            onClick={handleSubmit}
            height="h-14"
            isCheckout={false}
          />
        </DialogFooter>
        {isLoading && <OverlaySpinner />}
      </GenericDialog>
    </>
  );
};

export default SurveyPopup;
