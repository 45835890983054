import React from "react";
import shopfloLogo from "assests/images/shopflo-secured-logo.svg";
import { useLocale } from "lib/hooks/useLocale";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";

interface TermsConditionsProps {
  showSecuredLogo?: boolean;
}

const TermsConditions: React.FC<TermsConditionsProps> = ({ showSecuredLogo = true }) => {
  const { t } = useLocale();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  return (
    <div className="flex flex-col items-center gap-3">
      <>
        <p className="pt-1 text-[10px] font-normal text-coal-light">
          {t("by_proceeding_text")}{" "}
          <a
            href="https://shopflo.com/terms-conditions?utm_source=shopflo-checkout&utm_medium=shopflo-checkout&utm_campaign=shopflo-checkout"
            className="font-bold"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              sendAnalyticsEvent({
                eventName: analyticsEvents.FLO_TERMS_AND_CONDITIONS,
                eventType: "click",
              });
            }}>
            {t("terms_and_conditions")}
          </a>
          &nbsp;{t("and")}&nbsp;
          <a
            href="https://shopflo.com/privacy-policy?utm_source=shopflo-checkout&utm_medium=shopflo-checkout&utm_campaign=shopflo-checkout"
            className="font-bold"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              sendAnalyticsEvent({
                eventName: analyticsEvents.FLO_PRIVACY_POLICY,
                eventType: "click",
              });
            }}>
            {t("privacy_policy")}
          </a>
        </p>
      </>
      {Boolean(showSecuredLogo) && <img src={shopfloLogo} alt={"shopflo-logo"} className="h-3.5" />}
    </div>
  );
};

export default TermsConditions;
