import React, { createContext, useContext, useRef, useState, } from 'react';
import { putRequest } from 'lib/core/apiClient';
import { useSearchParams, useLocation } from 'react-router-dom';
import useSendAnalyticsEvent from 'lib/hooks/useAnalytics';
import { useUserContext } from 'lib/contexts/UserProvider';
import { useAuthContext } from 'lib/contexts/AuthProvider';
import { useCheckoutContext } from 'lib/contexts/CheckoutProvider';
import { useMerchantContext } from 'lib/contexts/MerchantProvider';
import { parseUserData } from 'lib/utils/user';
import { UserType, UserLoginType } from 'lib/types/user';
import { getItems, initializeCheckout } from 'lib/utils/checkout';
import { isEmptyObj } from 'lib/utils/helpers';
import { analyticsEvents } from 'lib/utils/constants';
import { checkoutSyncPrices } from 'lib/core/apiMethods';

interface InitializeContextType {
  isLoading: boolean;
  isPayBtnLoading: boolean;
  showHeader: boolean;
  showOrderSummary: boolean;
  showOrderSummaryTotalTxt: boolean;
  initialize: () => Promise<void>;
}

const InitializeContext = createContext<InitializeContextType | undefined>(undefined);

export const InitializeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isCheckoutInteractive = useRef(false);
  const showOrderSummaryTotalTxt = useRef(false);
  const userLoginTypeTemp = useRef<UserLoginType>();

  const [searchParams] = useSearchParams();
  const analytics = useSendAnalyticsEvent();
  const { sendAnalyticsEvent } = analytics;

  const userContext = useUserContext();
  const {
    state: { marketingConsent, userLoginType },
    actions: { setUserData, setMarketingConsent },
  } = userContext;

  const authContext = useAuthContext();
  const {
    state: { isAuthenticated },
    actions: { logout },
  } = authContext;

  const checkoutContext = useCheckoutContext();
  const {
    actions: { updateCheckoutBasedOnCheckoutResponse },
  } = checkoutContext;
  const {
    actions: { setCheckoutExpired },
  } = checkoutContext;

  const merchantContext = useMerchantContext();
  const { hash: hashParam } = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPayBtnLoading, setIsPayBtnLoading] = useState<boolean>(true);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showOrderSummary, setShowOrderSummary] = useState<boolean>(true);
  
  const updateMarketingConsent = async () => {
    if (marketingConsent === undefined) return;
    if (!Boolean(isAuthenticated)) return;
    try {
      await putRequest("/attributes/v1/account-attributes", {
        attributes: {
          marketing_consent: marketingConsent,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };


  const handleResponseSuccess = (checkoutResponse: any, accountResponse: any, tokenData: any) => {
    handleAccountResponse(accountResponse, checkoutResponse, tokenData);
    checkSyncPricesFlag(checkoutResponse);
  };

  const checkSyncPricesFlag = async (checkoutResponse: any) => {
    handleCheckoutResponse(checkoutResponse);
    if (checkoutResponse?.sync_needed && Boolean(checkoutResponse?.addresses)) {
      checkoutResponse = await checkoutSyncPrices(checkoutResponse?.uid);
      handleCheckoutResponse(checkoutResponse);
    }
    setIsPayBtnLoading(false);
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    if (checkoutResponse?.metadata?.ui_config) {
      const availableElements = checkoutResponse?.metadata?.ui_config?.layout?.available_elements;
      if (availableElements) {
        setShowHeader(Boolean(availableElements?.includes("HEADER")));
        setShowOrderSummary(Boolean(availableElements?.includes("ORDER_SUMMARY")));
      }
    }

    updateCheckoutBasedOnCheckoutResponse(checkoutResponse);

    updateMarketingConsent();
  };


  const handleAccountResponse = (accountResponse: any, checkoutResponse: any, tokenData: any) => {
    if (!Boolean(accountResponse) || isEmptyObj(accountResponse)) {
      if (isAuthenticated) {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_AUTH_LOGIN_COMPLETED,
          eventType: "load",
          metaData: {
            checkoutId: checkoutResponse.uid,
            checkoutToken: checkoutResponse?.checkout_token_id,
            shopifySessionId: tokenData?.sf_session_id,
            merchantId: checkoutResponse?.merchant_id,
            userData: {
              type: userLoginTypeTemp.current ?? userLoginType,
              phone: null,
              email: null,
              accountResponse: accountResponse ?? {},
            },
            checkoutItems: getItems(checkoutResponse.items),
          },
          skipState: checkoutResponse?.metadata?.ui_config?.default_view === "PAYMENTS",
        });
      }
      return;
    }

    const parsedUserData: UserType = parseUserData(accountResponse, checkoutResponse);
    setUserData(parsedUserData);
    const marketingConsent = Boolean(accountResponse?.account_attributes?.attributes?.marketing_consent);
    setMarketingConsent(marketingConsent);
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_AUTH_LOGIN_COMPLETED,
      eventType: "load",
      metaData: {
        checkoutId: checkoutResponse.uid,
        checkoutToken: checkoutResponse?.checkout_token_id,
        shopifySessionId: tokenData?.sf_session_id,
        merchantId: checkoutResponse?.merchant_id,
        userData: {
          type: userLoginTypeTemp ?? userLoginType,
          phone: parsedUserData.phone,
          email: parsedUserData.email,
          user_id: parsedUserData.uid,
        },
        checkoutItems: getItems(checkoutResponse.items),
      },
      skipState: checkoutResponse?.metadata?.ui_config?.default_view === "PAYMENTS",
    });
  };

  const initialize = async () => {
    setIsLoading(true);
    try {
      const response = await initializeCheckout({
        tokenId: searchParams.get("tokenId"),
        checkoutIdParam: searchParams.get("checkoutId"),
        analytics,
        userContext,
        authContext,
        checkoutContext,
        merchantContext,
        isCheckoutInteractive: isCheckoutInteractive.current,
        hashParam,
      });
      if (response !== undefined) {
        const { checkoutResponse, accountResponse, tokenData } = response;
        showOrderSummaryTotalTxt.current =
          checkoutResponse?.metadata?.ui_config?.layout?.metadata?.show_order_summary_total ?? false;

        handleResponseSuccess(checkoutResponse, accountResponse, tokenData);

        isCheckoutInteractive.current = response.isCheckoutInteractive;
      }
      setIsLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 403) logout();
      if (
        error?.response?.status === 400 &&
        (error?.response?.data?.error === "C2P flow timedout" ||
          error?.response?.data?.error === "C2P is already completed")
      ) {
        setCheckoutExpired(true);
      }
      setIsLoading(false);
    }
  };

  const contextValue = {
    isLoading,
    isPayBtnLoading,
    showHeader,
    showOrderSummary,
    showOrderSummaryTotalTxt: showOrderSummaryTotalTxt.current,
    initialize,
  };

  return (
    <InitializeContext.Provider value={contextValue}>
      {children}
    </InitializeContext.Provider>
  );
};

export const useInitialize = () => {
  const context = useContext(InitializeContext);
  if (context === undefined) {
    throw new Error('useInitialize must be used within an InitializeProvider');
  }
  return context;
};