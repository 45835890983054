import { XIcon } from "@heroicons/react/solid";
import GenericButton from "components/common/buttons/GenericButton";
import { DialogBody, DialogHeader } from "components/common/dialogs/GenericDialog";
import { useUserContext } from "lib/contexts/UserProvider";
import { useLocale } from "lib/hooks/useLocale";
import React from "react";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";

interface LogoutProps {
  setIsOpen: (value: boolean) => void;
  handleLogout: () => void;
  setShowLogoutConfirmation: (value: boolean) => void;
}

const Logout: React.FC<LogoutProps> = ({ setIsOpen, handleLogout, setShowLogoutConfirmation }) => {
  const { t } = useLocale();
  const {
    state: { user },
  } = useUserContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  return (
    <>
      <DialogHeader>
        <div className="flex w-full items-center justify-between text-base">
          <h1 className="font-medium text-carbon-dark">{t("logout_dialog_header")}</h1>
          <button className="outline-none">
            <XIcon className="h-6 w-6 cursor-pointer text-coal-dark" onClick={() => setIsOpen(false)} />
          </button>
        </div>
      </DialogHeader>
      <DialogBody>
        <div className="mx-6 flex flex-col items-center gap-6 ">
          <p className="text-sm text-gray-dark ">{t("logout_dialog_info", { phone: user?.phone })}</p>
          <div className="flex w-full flex-col gap-3">
            <GenericButton
              buttonText={t("yes_logout")}
              height="h-14"
              customClass="bg-primary-dark rounded-[12px] text-btnPrimaryText font-semibold"
              onClick={handleLogout}
            />
            <GenericButton
              buttonText={t("no")}
              height="h-14"
              customClass="border-primary-dark border rounded-[12px] text-primary-dark font-semibold"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
          <p
            className="cursor-pointer text-sm font-medium text-coal-light underline"
            onClick={() => {
              setShowLogoutConfirmation(true);
              sendAnalyticsEvent({
                eventName: analyticsEvents.FLO_ADDRESS_PRIVACY_CLICKED,
                eventType: "click",
              });
            }}>
            {t("logout_dialog_footer")}
          </p>
        </div>
      </DialogBody>
    </>
  );
};

export default Logout;
