export const constants = {
  AUTH_COOKIE_CLIENT: "flo_auth",
  REFRESH_TOKEN_COOKIE_CLIENT: "flo_refresh",
  AUTH_LOGGED_OUT: "flo_auth_logged_out",
  AUTH_TOKEN_HEADER_SERVER: "Authorization",
  AUTH_COOKIE_CLIENT_EXPIRY: 1 / 32,
  REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY: 365,
  ENV_PROD: "production",
  ENV_DEV: "development",
  ENV_TEST: "staging",
  PAYMENT_FAILURE_THRESHOLD: 5,
  FLO_SESSION_ID_XHR_HEADER: "x-shopflo-session",
  FLO_SESSION_ID_COOKIE: "flo_session_id",
  TWO_STEP_AUTH_COOKIE_CLIENT: "flo_two_step_auth",
  CHECKOUT_HOST: import.meta.env.VITE_CHECKOUT_HOST,
};

export const eventTypes = {
  ANALYTICS: "analytics",
  CLOSE_IFRAME: "CLOSE_IFRAME",
  CLOSE_CART_IFRAME: "CLOSE_CART_IFRAME",
  CLOSE_IFRAME_BUYNOW: "CLOSE_IFRAME_BUYNOW",
  SET_COOKIE: "SET_COOKIE",
  GET_COOKIE: "GET_COOKIE",
  PARENT_REDIRECT: "PARENT_REDIRECT",
  CART_OPEN: "FLO_CART_OPEN",
  CART_UPDATE: "FLO_CART_UPDATE",
  ITEM_UPDATE: "FLO_ITEM_UPDATE",
  COUPONS_UPDATED: "FLO_COUPONS_UPDATED",
  EXIT_CHECKOUT: "FLO_EXIT_CHECKOUT",
  CART_TO_CHECKOUT: "FLO_CART_TO_CHECKOUT",
  CLEAR_CART_AND_CLOSE: "CLEAR_CART_AND_CLOSE",
  LOGOUT_FROM_CHECKOUT: "LOGOUT_FROM_CHECKOUT",
  LOGIN_FROM_CHECKOUT: "LOGIN_FROM_CHECKOUT",
  SSO_LOGIN_CREDENTIALS: "SSO_LOGIN_CREDENTIALS",
  SSO_LOGIN_SUCCESS: "SSO_LOGIN_SUCCESS",
  LOGIN_RE_INIT: "LOGIN_RE_INIT",
  FLO_USER_DETAILS: "FLO_USER_DETAILS",
  FLO_GHOST_USER: "FLO_GHOST_USER",
  FLO_GHOST_USER_ORDER_DATA: "FLO_GHOST_USER_ORDER_DATA",
  GET_ANALYTICS_CONFIG: "GET_ANALYTICS_CONFIG",
  SSO_LOGIN_CHECK: "SSO_LOGIN_CHECK",
};

export const analyticsEvents = {
  FLO_CHECKOUT_UI_LOADED: "checkout_ui_loaded", //can't send payload and other parameters as they are generated after the event gets triggered

  FLO_ORDER_SUMMARY_BTN_CLICKED: "order_summary_clicked",

  FLO_AUTH_PHONE_LOADED: "login_page_loaded",
  FLO_AUTH_PHONE_ENTERED: "phone_entered",
  FLO_AUTH_OTP_LOADED: "otp_page_loaded",
  FLO_AUTH_OTP_ENTERED: "otp_entered",
  FLO_AUTH_OTP_RESEND: "otp_resend",
  FLO_AUTH_LOGIN_COMPLETED: "login_completed",
  FLO_LOGOUT_BTN_CLICKED: "logged_out",
  FLO_BACK_CLICK: "back_button_clicked",
  FLO_USER_FLOW_INIT: "user_flow_initialized",
  FLO_EXIT_LOGOUT_CLICKED: "exit_logout_clicked",
  FLO_LOGOUT_CLICKED: "logout_clicked",
  FLO_COOKIE_LOGIN_ENABLED: "cookie_login_enabled",
  FLO_COOKIE_LOGIN_DISABLED: "cookie_login_disabled",

  FLO_COUPON_LOADED: "coupons_loaded",
  FLO_COUPON_ENTERED: "coupon_entered",
  FLO_COUPON_SELECTED: "coupon_selected",
  FLO_COUPON_SUCCESS: "coupon_success",
  FLO_COUPON_FAILED: "coupon_failed",
  FLO_COUPON_REMOVED: "coupon_removed",

  FLO_ADDRESSES_LIST_LOADED: "address_list_loaded",
  FLO_ADDRESS_SELECTED: "address_selected",
  FLO_ADDRESS_NEW_FORM_LOADED: "address_new_form_loaded",
  FLO_ADDRESS_NEW_FORM_SUBMITTED: "address_new_form_submitted",
  FLO_ADDRESS_EDIT_FORM_LOADED: "address_edit_form_loaded",
  FLO_ADDRESS_EDIT_FORM_SUBMITTED: "address_edit_form_submitted",
  FLO_DELIVERY_ADDRESS_CHANGE_CLICKED: "delivery_address_button_clicked",
  FLO_ADDRESS_PRIVACY_CLICKED: "adress_privacy_clicked",
  FLO_ADDRESS_CONSENT_ENABLED: "address_consent_enabled",
  FLO_ADDRESS_CONSENT_DISABLED: "address_consent_disabled",

  FLO_BILLING_ADDRESS_EDIT_FORM_LOADED: "billing_address_edit_form_loaded",
  FLO_BILLING_ADDRESS_SUBMITTED: "billing_address_submitted",
  FLO_BILLING_ADDRESS_CHANGE_CLICKED: "billing_address_button_clicked",

  FLO_PAYMENT_LOADED: "payment_page_loaded",
  FLO_PAYMENT_METHOD_SELECTED: "payment_method_selected",
  FLO_PAYMENT_MODE_SELECTED: "payment_mode_selected",
  FLO_PAYMENT_PG_PAGE_LOADED: "payment_pg_page_loaded",
  FLO_PAYMENT_COMPLETED: "payment_completed_ui",
  FLO_PAYMENT_TILE_SELECTED: "payment_tile_selected",

  FLO_GUEST_LOGIN: "guest_login",
  FLO_RETURN_USER_LOGIN: "return_user_login",
  FLO_COOKIE_LOGIN: "cookie_login",

  FLO_COD_OTP_LOADED: "cod_otp_confirm_loaded",
  FLO_COD_OTP_SENT: "cod_otp_sent",
  FLO_COD_OTP_ENTERED: "cod_otp_entered",
  FLO_COD_OTP_VERIFIED: "cod_otp_verified",
  FLO_COD_OTP_SKIP: "cod_otp_skipped",
  FLO_COD_OTP_RESEND: "cod_otp_resend",

  FLO_COD_CONFIRMATION_LOADED: "cod_confirmation_loaded",
  FLO_COD_CONFIRMED: "cod_confirmed", //Event fired when COD option is confirmed in the confirmation dialog
  FLO_COD_PREPAID_CLICKED: "cod_prepaid_clicked", //Event fired when Online option is clicked in the confirmation dialog

  FLO_TERMS_AND_CONDITIONS: "terms_and_conditions_clicked",
  FLO_PRIVACY_POLICY: "privacy_policy_clicked",

  FLO_SHIPPING_HANDLES_LOADED: "shipping_handles_loaded",
  FLO_SHIPPING_HANDLE_SELECTED: "shipping_handle_selected",

  FLO_EXIT_BANNER_ACTION: "exit_banner_action",
  FLO_TRUECALLER_ACTION: "truecaller_action",

  FLO_TRUST_BADGE_CLICKED: "trust_badge_clicked",
  FLO_STORE_PAGE_VIEW: "store_page_view",

  FLO_SURVEY_FORM_LOADED: "survey_form_loaded",
  FLO_SURVEY_FORM_ANSWERED: "survey_form_answered",
  FLO_SURVEY_FORM_SKIPPED: "survey_form_skipped",

  FLO_SSO_PAGE_LOADED: "sso_page_loaded",
  FLO_SSO_PHONE_ADDED: "sso_phone_added",
  FLO_SSO_OTP_LOADED: "sso_otp_loaded",
  FLO_SSO_OTP_RESEND: "sso_otp_resend",
  FLO_SSO_OTP_ENTERED: "sso_otp_entered",
  FLO_SSO_FORM_LOADED: "sso_form_loaded",
  FLO_SSO_FORM_SUBMITTED: "sso_form_submitted",
  FLO_SSO_POP_CLOSED: "sso_pop_closed",
  // FLO_SSO_USER_VERIFY: "sso_user_verify",
  // FLO_SSO_USER_DETAILS_CHANGED: "sso_user_details_changed",
  FLO_SSO_LOGIN_COMPLETED: "sso_login_completed",
  FLO_SSO_AUTH_LOADED: "sso_auth_loaded",
  // FLO_SSO_CONSENT_REMOVED: "sso_consent_removed",

  // cart to checkout
  FLO_CHECKOUT_CLICKED: "checkout_clicked",
  FLO_ADDED_TO_CART_UI: "added_to_cart_ui",

  UPSELL_WIDGET_LOADED: "upsell_widget_loaded",
  UPSELL_PRODUCT_ADDED: "upsell_product_added",
};

export const keys = {
  KEY_ENTER: "Enter",
};

export const analyticsTypes = {
  SF_ANALYTICS: "SHOPFLO_ANALYTICS",
  GOOGLE_ANALYTICS: "GOOGLE_ANALYTICS",
  FACEBOOK_PIXEL: "FACEBOOK_PIXEL",
};

export const pageName = {
  LOADING_PAGE: "loading_page",
  LOGIN_PAGE: "login_page",
  HOME_PAGE: "home_page",
  EDIT_ADDRESS_PAGE: "edit_address_page",
  NEW_ADDRESS_PAGE: "new_address_page",
  COUPON_PAGE: "coupon_page",
  PAYMENT_PAGE: "payment_page",
  COD_OTP_PAGE: "cod_confirmation",
  LOADER: "loader_page",
};

export const paymentRetryModes = {
  change_payment: "CHANGE_PAYMENT_METHOD",
  retry_payment: "RETRY_PAYMENT",
  convert_to_cod: "CONVERT_TO_COD",
  send_whatsapp_link: "SEND_WHATSAPP_LINK",
  place_order_again: "PLACE_ORDER_AGAIN",
  close_payment: "CLOSE_PAYMENT",
};

export const routing = {
  PAYMENT_STATUS: "payment-status",
  page: "page",
};

export const trustBadges = {
  CLOCK: "clock",
  GLOBE: "globe",
  LOCK: "lock",
  RETURN: "return",
  SMILE: "smile",
  STAR: "star",
  TRUCK: "truck",
};

export const clartityTags = {
  PAGE_VISITED: "page_visited",
  MERCHANT_ID: "merchant_id",
  SHOP_NAME: "shop_name",
  CHANNEL: "channel",
};

export enum AddressSource {
  BILLING_ADDRESS = "BILLING_ADDRESS",
  SHIPPING_ADDRESS = "SHIPPING_ADDRESS",
};
