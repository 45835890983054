import React, { useEffect, useState, Suspense } from "react";
import { useLocale } from "lib/hooks/useLocale";
import GenericButton from "components/common/buttons/GenericButton";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { useUserContext } from "lib/contexts/UserProvider";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import { Truck, Edit, } from "react-feather";
import AddressModify from "components/address/dialogs/AddressModify";
import AddressForm from "components/address/dialogs/AddressForm";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { getDefaultShippingHandle, hasAutoAppliedCoupon } from "lib/utils/checkout";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import OOSDialog from "components/checkout/dialogs/OOSDialog";
import { useLocation } from "react-router-dom";
import {
  checkMandatoryFields,
  setPageClarityTags,
} from "lib/utils/helpers";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import Coupons from "components/checkout/Coupons";
import ShippingHandlesSection from "components/checkout/dialogs/ShippingHandlesSection";
import AddressListDialog from "components/address/dialogs/AddressListDialog";
import { AddressCardFields } from "lib/types/address";
import AddressSection from "components/address/dialogs/AddressSection";
import { usePrevious } from "lib/hooks/usePrevious.ts";

interface AuthenticatedViewsProps {
  isPrimaryBtnLoading?: boolean;
}
const Payments = React.lazy(() => import("../../payments/Payments.tsx"));
const ShippingHandlesDialog = React.lazy(() => import("../../checkout/dialogs/ShippingHandlesDialog.tsx"));
const BillingAddress = React.lazy(() => import("../../address/BillingAddress.tsx"));
const UpSellSection = React.lazy(() => import("../../upsell/UpSellSection.tsx"));

const AuthenticatedViews: React.FC<AuthenticatedViewsProps> = ({ isPrimaryBtnLoading = false }) => {
  const { t } = useLocale();
  const {
    state: {
      isC2P,
      checkoutView,
      checkoutModal,
      shippingHandles,
      isTwoStepCheckout,
      checkoutUIMetadata,
      initialCheckoutStep,
      appliedCoupons,
      checkoutValidations,
    },
    actions: { setCheckoutModal, setCheckoutView, setCheckoutValidations },
  } = useCheckoutContext();
  const {
    state: { user },
  } = useUserContext();
  const {
    state: { merchant, addressConfig },
  } = useMerchantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { hash: hashParam } = useLocation();

  const [editAddressId, setEditAddressId] = useState<string>("ADDRESS_NEW");
  const [selectedShippingHandleEtd, setSelectedShippingHandleEtd] = useState<string>("");
  const [initialLoad, setInitialLoad] = useState<Boolean>(false);

  const deliveryConfig = merchant?.addressConfig?.deliveryConfig;
  const billingAddressEnabled = merchant?.addressConfig?.isBillingAddressEnabled;

  useEffect(() => {
    if (Boolean(hashParam?.length)) {
      handleHashParams();
      return;
    }
    setCheckoutView("ADDRESS_LIST", true);
  }, []);

  useEffect(() => {
    if (Boolean(isC2P) || initialCheckoutStep === "PAYMENTS") {
      setCheckoutView("PAYMENTS");
      verifyDefaultAddress();
      if (Boolean(user?.addresses?.length)) {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_ADDRESS_SELECTED,
          eventType: "click",
        });
      }
    }
  }, [isC2P]);

  useEffect(() => {
    if (isTwoStepCheckout) return;
    if (checkoutModal === "ADDRESS_NEW") {
      return;
    }
    setPageClarityTags(checkoutView);
  }, [checkoutView]);

  const previousUserAddress = usePrevious(user?.addresses);
  useEffect(() => {
    if (isTwoStepCheckout) return;
    if (!Boolean(user?.addresses) && previousUserAddress === user?.addresses) {
      setCheckoutModal("ADDRESS_NEW");
      return;
    }
  }, [user]);

  useEffect(() => {
    if (!Boolean(shippingHandles?.length)) return;
    const selectedHandle = getDefaultShippingHandle(shippingHandles);
    if (Boolean(selectedHandle)) {
      setSelectedShippingHandleEtd(selectedHandle?.etd ?? "");
    }
  }, [shippingHandles]);

  const handleHashParams = () => {
    const hashValue = hashParam.slice(1);
    switch (hashValue) {
      case "PAYMENTS":
      case "COD":
        setCheckoutView("PAYMENTS");
        return;
      default:
        return;
    }
  };

  const verifyDefaultAddress = () => {
    const selectedAddressId = user?.default_shipping_address?.id ? user?.default_shipping_address?.id : "";
    const selectedAddress = user?.addresses?.find(
      (address: AddressCardFields) => address?.id === selectedAddressId,
    );
    if (!selectedAddress) return;
    const addressValidity = checkMandatoryFields(selectedAddress, addressConfig);
    if (!addressValidity.available) {
      handleEditAddress(selectedAddressId);
      setCheckoutValidations({
        ...checkoutValidations,
        address: {
          isValid: false,
          metadata: {
            addressId: selectedAddressId,
            fieldData: addressValidity?.errorFieldData,
          },
        },
      });
      return;
    }
    setCheckoutValidations({
      ...checkoutValidations,
      address: {
        isValid: true,
      },
    });
  };

  const handleEditAddress = (id: string) => {
    setEditAddressId(id);
    setCheckoutModal("ADDRESS_EDIT");
  };

  const handleDeliveryAddressBtnClick = () => {
    if (isC2P) return;
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_DELIVERY_ADDRESS_CHANGE_CLICKED,
      eventType: "click",
    });
    setCheckoutView("ADDRESS_LIST");
  };

  return (
    <>
      {checkoutView === "ADDRESS_LIST" && (
        <>
          <div className="border-base mx-3 mt-3 flex flex-col bg-white">
            <div
              className={`flex w-full flex-col gap-4 overflow-x-hidden rounded-b-[1.75rem] px-3 pt-4 ${
                isTwoStepCheckout ? "pb-4" : "pb-3"
              }`}>
              <div className="flex w-full items-center justify-between space-x-2">
                <div className="flex space-x-2">
                  <Truck className="h-4 w-4 text-coal-dark outline-none" strokeWidth={2.5} />
                  <h2 className="text-sm font-medium text-coal-dark">{t("delivering_to_address")}</h2>
                </div>
                {deliveryConfig?.isEnabled && (
                  <p className="flex w-1/2 grow justify-end overflow-hidden text-ellipsis text-sm font-normal text-yay-dark">
                    <span className="truncate">{deliveryConfig?.deliveryText}</span>
                  </p>
                )}
              </div>
              <div>
                <div className="flex flex-col gap-4">
                  {isTwoStepCheckout ? (
                    <AddressForm addressId={editAddressId} />
                  ) : (
                    <>
                      <GenericButton
                        height="h-12"
                        width="w-full"
                        id="flo_add__address"
                        customClass="text-sm font-medium text-coal-dark space-x-2 font-medium border-[1px] border-gray-light rounded-2xl px-3 flex items-center justify-start hover:ring-gray-light hover:ring-[2px]"
                        buttonText={t("add_new_address_button")}
                        iconComponent={<PlusCircleIcon className="h-5 w-5 text-yay-dark outline-none" />}
                        onClick={() => {
                          setEditAddressId("ADDRESS_NEW");
                          setCheckoutModal("ADDRESS_NEW");
                        }}
                      />
                      <AddressModify
                        handleEditAddress={handleEditAddress}
                        isPrimaryBtnLoading={isPrimaryBtnLoading}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS" && (
        <>
          <div className="flex flex-col">
            <div className={`flex flex-col px-3 pt-3 ${billingAddressEnabled ? "pb-1.5" : "pb-3"}`}>
              <button
                className={`border-base flex w-full flex-col items-center gap-3 bg-white p-3 pt-4 ${
                  isC2P && "cursor-default"
                }`}
                onClick={handleDeliveryAddressBtnClick}>
                <div className="flex w-full flex-row items-center">
                  <div className="flex w-full items-center justify-between space-x-2">
                    <div className="flex space-x-2">
                      <Truck className="h-4 w-4 text-coal-dark outline-none" strokeWidth={2.5} />
                      <h2 className="text-sm font-medium text-coal-dark">{t("delivering_to_address")}</h2>
                    </div>
                  </div>
                  {!isC2P && <Edit className="h-4 w-4 text-coal-dark" />}
                </div>

                <div className="flex w-full flex-col space-y-1 rounded-md bg-gray-lighter px-1.5 py-2">
                  <p className="w-full px-[3px] text-left text-xs font-normal text-coal-light">
                    {user?.default_shipping_address?.address}
                  </p>
                </div>
                {Boolean(selectedShippingHandleEtd) && (
                  <p className="flex w-full grow justify-start overflow-hidden text-ellipsis text-left text-xs font-medium text-coal-light">
                    <span className="truncate">
                      {t("delivery_information_with_days", { days: selectedShippingHandleEtd })}
                    </span>
                  </p>
                )}
              </button>
            </div>
            {/* Billing Address */}
            <BillingAddress />
            {merchant?.showShippingOnPayments && (
              <div className="w-full pb-2">
                <ShippingHandlesSection loadPayments={() => setInitialLoad(true)} />
              </div>
            )}

            {Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled) && !isC2P && (
              <Suspense fallback={<OverlaySpinner />}>
                <div className="px-3 pb-3 pt-1.5">
                  <UpSellSection />
                </div>
              </Suspense>
            )}
            <Suspense fallback={<OverlaySpinner />}>
              <Payments />
            </Suspense>
          </div>
        </>
      )}
      {checkoutView === "PAYMENTS" && initialCheckoutStep === "PAYMENTS" && (
        <>
          <div className="flex flex-col">
            <div className={`flex w-full flex-row items-center justify-between px-5 py-3 text-xs`}>
              <h2 className="text-sm font-medium text-coal-light">{t("delivery_information")}</h2>
            </div>
            <AddressSection />
            {/* Billing Address */}
            <BillingAddress />
            <ShippingHandlesSection loadPayments={() => setInitialLoad(true)} />

            <div className={`flex w-full flex-row  items-center justify-between px-5 py-3 text-xs`}>
              <h2 className="text-sm font-medium text-coal-light">{t("offers_and_rewards")}</h2>
            </div>
            {(hasAutoAppliedCoupon(appliedCoupons) || merchant?.isDiscountEnabled) && (
              <div className="mb-3 px-3 ">
                <Coupons />
              </div>
            )}
            {initialLoad && (
              <Suspense fallback={<OverlaySpinner />}>
                <Payments />
              </Suspense>
            )}
          </div>
        </>
      )}

      <GenericDialog
        isOpen={checkoutModal === "ADDRESS_LIST"}
        setIsOpen={() => {
          setCheckoutModal("NONE");
        }}
        translateAxis="y"
        dialogOverlay={true}
        customClass="h-[92%] md:h-[77vh] md:top-[8vh]"
        modalType="ADDRESS_LIST">
        <AddressListDialog
          setIsOpen={() => setCheckoutModal("NONE")}
          isPrimaryBtnLoading={isPrimaryBtnLoading}
        />
      </GenericDialog>

      <GenericDialog
        isOpen={checkoutModal === "ADDRESS_NEW" || checkoutModal === "ADDRESS_EDIT"}
        setIsOpen={() => {
          setCheckoutModal("NONE");
          setInitialLoad(true);
        }}
        translateAxis="y"
        dialogOverlay={true}
        customClass="h-[92%] md:h-[77vh] md:top-[8vh]"
        modalType="ADDRESS_NEW">
        <AddressForm
          addressId={editAddressId}
          initialFormErrors={checkoutValidations.address?.metadata?.fieldData}
        />
      </GenericDialog>

      <GenericDialog
        isOpen={checkoutModal === "SHIPPING_HANDLES"}
        setIsOpen={() => setCheckoutModal("NONE")}
        translateAxis="y"
        customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
        dialogOverlay={true}
        modalType="SHIPPING_HANDLES">
        <ShippingHandlesDialog />
      </GenericDialog>

      <GenericDialog
        isOpen={checkoutModal === "OOS"}
        setIsOpen={() => setCheckoutModal("NONE")}
        translateAxis="y"
        dialogOverlay={true}
        customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
        modalType="OOS">
        <OOSDialog />
      </GenericDialog>
    </>
  );
};

export default AuthenticatedViews;
